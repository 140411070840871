import { withStyles } from 'tss-react/mui';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: '26px',
    fontWeight: 600,
    color: '#131313',
    lineHeight: '32px',
  },
  children: {
    width: '100%',
    padding: '20px 20px',
  },
});

const CardView = ({ children, classes, title }) => (
  <Grid container className={classes.root}>
    <Grid container className={classes.title}>
      <Typography variant="inherit">{title}</Typography>
    </Grid>
    <div className={classes.children}>{children}</div>
  </Grid>
);

export const Card = withStyles(CardView, styles);
