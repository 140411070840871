import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchKyc: initialFetching,
  kyc: {},
};

const symbiotes = {
  fetchKyc: createFetching('fetchKyc'),
  setKycToken: (state, data) => ({ ...state, kyc: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'kyc');
