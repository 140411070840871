import { handleFetching } from 'symbiote-fetching';
import { useEffect, useRef, useState } from 'react';

import { mapStructuredDeposit } from 'features/offering/mappers';
import { getResponse } from 'features/common/response';
import { PositionTypes } from 'features/common/models';

import { actions } from './symbiotes';
import { setPositionsActionsModel } from './models';
import {
  mapWallet,
  mapCommission,
  mapBankDetail,
  mapBankDetails,
  mapCryptoWithdrawalPosition,
  mapCurrencyWithdrawalPosition,
  mapCurrencyAcquiringPositions,
  mapAcquiringCheckouts,
  mapAcquiringCheckoutsPayment,
  mapCountries,
  mapProductPositions,
} from './mappers';
import { walletApi } from './api';

export const getWallet = (query, isActive = true) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        let response = await dispatch(isActive ? walletApi.getWallet : walletApi.getContract, query);
        const { ok, error, data } = getResponse(response);
        let wallet = data;
        if (!isActive) {
          response = await dispatch(walletApi.getMinDeposit, query);
          const { ok, error, data } = getResponse(response);
          wallet = { ...wallet, ...data };
          if (ok) {
            dispatch(actions.setWallet(mapWallet(wallet)));
          }
          return {
            ok,
            error,
            data,
          };
        }
        if (ok) {
          dispatch(actions.setWallet(mapWallet(wallet)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getCommission = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getCommission, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setCommission(mapCommission(response)));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const withdraw = data =>
  handleFetching(actions.fetchWithdraw, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.withdraw, data);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const withdrawCurrency = data =>
  handleFetching(actions.fetchWithdraw, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.withdrawCurrency, data);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPositions = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPositions, query);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const useCheckPositionConversion = (
  quantity, settings, checkPosition, currencyFrom, currencyTo, currencyFromTicker, currencyToTicker, show, error, onPriceChangedEvent
) => {
  const [ updateEvent, setUpdateEvent ] = useState({});
  useEffect(() => {
    if (show && (onPriceChangedEvent?.tickers?.includes(currencyFromTicker) || onPriceChangedEvent?.tickers?.includes(currencyToTicker))) {
      setUpdateEvent({});
    }
  }, [ show, currencyFromTicker, currencyToTicker, onPriceChangedEvent ]);

  useEffect(() => {
    let form = {  };
    if (currencyTo) {
      form.Quantity =  +quantity;
    } else {
      form.Quantity = 0;
    }
    if (currencyFrom) {
      form.SpendSymbolId = currencyFrom;
    } else {
      delete form.SpendSymbolId;
    }
    if (currencyTo) {
      form.GetSymbolId = currencyTo;
    } else {
      delete form.GetSymbolId;
    }
    const timer = setTimeout(() => {
      if (currencyFrom && show && !error) {
        checkPosition(form);
      }
    }, 400);

    return () => {
      clearTimeout(timer);
    };
  }, [  quantity, checkPosition, currencyFrom, currencyTo, show, error, updateEvent, currencyFromTicker, settings ]);
};

export const checkPositionConversion = query =>
  handleFetching(actions.fetchConversionPosition, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.conversionCheckPosition, query);
        let { ok, error, data } = getResponse(response);
        if (ok) {
          if (query && (query.Quantity || query.Quantity === 0) && query.SpendSymbolId) {
            dispatch(actions.setPositionConversion(response.source_data));
          }
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const cryptoAutoConvert = data =>
  handleFetching(actions.fetchCryptoAutoConvert, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.cryptoAutoConvert, data);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAvailCryptoTickers = () =>
  handleFetching(actions.fetchAvailCryptoTickers, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getAvailCryptoTickers);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAvailCryptoTickers(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAvailAssetsToConvert = () =>
  handleFetching(actions.fetchAvailCryptoTickers, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getAvailAssetsToConvert);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAvailAssets(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPositionDueFee = query =>
  handleFetching(actions.fetchPositionDueFee, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setPositionDueFee({}));
        const response = await dispatch(walletApi.getStructuredDepositPositions, query);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setPositionDueFee(mapStructuredDeposit(response.source_data?.[0])));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicPositionDueFee = query =>
  handleFetching(actions.fetchPositionDueFee, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setPositionDueFee({}));
        const response = await dispatch(walletApi.getPublicStructuredDepositPositions, query);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setPositionDueFee(mapStructuredDeposit(response.source_data?.[0])));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const useTimeInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [ callback ]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [ delay ]);
};

export const getPublicCurrencyPositions = () =>
  handleFetching(actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicProductPositions, { SymbolType: PositionTypes.CURRENCY });;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setCurrencyPositions(mapProductPositions(response.source_data)?.[PositionTypes.CURRENCY] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicDGRPositions = () =>
  handleFetching(actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicProductPositions, { SymbolType: PositionTypes.DGR });;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setDGRPositions(mapProductPositions(response.source_data)?.[PositionTypes.DGR] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientDGRPositions = (settings = { withoutLoader: false }) =>
  handleFetching(settings.withoutLoader ? actions.fetchOne :  actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getUIProductPositions, { SymbolType: PositionTypes.DGR });;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setDGRPositions(mapProductPositions(response.source_data)?.[PositionTypes.DGR] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicFRTPositions = () =>
  handleFetching(actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicProductPositions, { SymbolType: PositionTypes.FRT });;;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setFRTPositions(mapProductPositions(response.source_data)?.[PositionTypes.FRT] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientFRTPositions = (settings = { withoutLoader: false }) =>
  handleFetching(settings.withoutLoader ? actions.fetchOne :  actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getUIProductPositions, { SymbolType: PositionTypes.FRT });;;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setFRTPositions(mapProductPositions(response.source_data)?.[PositionTypes.FRT] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientPositionByType = (type, settings = { withoutLoader: false }) =>
  handleFetching(settings.withoutLoader ? actions.fetchOne :  actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getUIProductPositions, { SymbolType: type });;
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(setPositionsActionsModel[type]?.(mapProductPositions(response.source_data)?.[type] ?? []));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientBankDetails = query =>
  handleFetching(actions.fetchClientBankDetails, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getClientBankDetails, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let bankDetail = [ ...response.source_data ];
          let bankDetails = [ ...response.source_data ];
          dispatch(actions.setClientBankDetail(mapBankDetail(bankDetail)));
          dispatch(actions.setClientBankDetails(mapBankDetails(bankDetails)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicBankDetails = query =>
  handleFetching(actions.fetchClientBankDetails, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicBankDetails, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let bankDetail = [ ...response.source_data ];
          let bankDetails = [ ...response.source_data ];
          dispatch(actions.setClientBankDetail(mapBankDetail(bankDetail)));
          dispatch(actions.setClientBankDetails(mapBankDetails(bankDetails)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkCryptoWithdrawalPosition = query =>
  handleFetching(actions.fetchCheckWithdrawalPosition, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.checkCryptoWithdrawalPosition, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setWithdrawalPosition(mapCryptoWithdrawalPosition(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkCurrencyWithdrawalPosition = query =>
  handleFetching(actions.fetchCheckWithdrawalPosition, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.checkCurrencyWithdrawalPosition, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setWithdrawalPosition(mapCurrencyWithdrawalPosition(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkCurrencyAcquiringPositions = query =>
  handleFetching(actions.fetchCheckCurrencyAcquiringPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.checkCurrencyAcquiringPositions, query);
        const { ok, error, data } = getResponse(response);
        dispatch(actions.setAcquiringPositions(mapCurrencyAcquiringPositions(response.source_data)));
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAcquiringCheckouts = query =>
  handleFetching(actions.fetchAcquiringCheckouts, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getAcquiringCheckouts, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAcquiringCheckouts(mapAcquiringCheckouts(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getCountries = query =>
  handleFetching(actions.fetchCountries, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getCountries, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setCountries(mapCountries(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAcquiringCheckoutsPayment = query =>
  handleFetching(actions.fetchAcquiringCheckoutsPayment, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getAcquiringCheckoutsPayment, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAcquiringCheckoutsPayment(mapAcquiringCheckoutsPayment(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientProductPositions = query =>
  handleFetching(actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getUIProductPositions, query);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          let productPositions = mapProductPositions(response.source_data);

          dispatch(actions.setFRTPositions(productPositions[PositionTypes.FRT] ?? []));
          dispatch(actions.setDGRPositions(productPositions[PositionTypes.DGR] ?? []));
          dispatch(actions.setStructuredDepositPositions(productPositions[PositionTypes.STRUCTURED_DEPOSIT] ?? []));
          dispatch(actions.setCurrencyPositions(productPositions[PositionTypes.CURRENCY] ?? []));
          dispatch(actions.setCryptoPositions(productPositions[PositionTypes.CRYPTO] ?? []));
          dispatch(actions.setAltcoinPositions(productPositions[PositionTypes.ALT_COIN] ?? []));
          dispatch(actions.setVnxPositions(productPositions[PositionTypes.VNX] ?? []));
          dispatch(actions.setProductPositionSymbols([].concat(...Object.values(productPositions))));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicProductPositions = () =>
  handleFetching(actions.fetchProductPositions, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicProductPositions);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          let productPositions = mapProductPositions(response.source_data);

          dispatch(actions.setFRTPositions(productPositions[PositionTypes.FRT] ?? []));
          dispatch(actions.setDGRPositions(productPositions[PositionTypes.DGR] ?? []));
          dispatch(actions.setStructuredDepositPositions(productPositions[PositionTypes.STRUCTURED_DEPOSIT] ?? []));
          dispatch(actions.setCurrencyPositions(productPositions[PositionTypes.CURRENCY] ?? []));
          dispatch(actions.setCryptoPositions(productPositions[PositionTypes.CRYPTO] ?? []));
          dispatch(actions.setAltcoinPositions(productPositions[PositionTypes.ALT_COIN] ?? []));
          dispatch(actions.setVnxPositions(productPositions[PositionTypes.VNX] ?? []));
          dispatch(actions.setProductPositionSymbols([].concat(...Object.values(productPositions))));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAvailFiatToConvert = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getAvailFiatToConvert, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setAvailFiat(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getLastTransactionBlockchain = Ticker =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getLastTransactionBlockchain, Ticker ? { Ticker } : {});
        const { ok, error, data } = getResponse(response);

        return {
          ok,
          error,
          data: data.source_data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getSessionForPaymentIQ = () =>
  handleFetching(actions.fetchSessionForPaymentIQ, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getSessionForPaymentIQ);
        const { ok, error, data } = getResponse(response);

        if (ok) {
          dispatch(actions.setSessionForPaymentIQ(response.source_data));
        }

        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
