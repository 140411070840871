import { withStyles } from 'tss-react/mui';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { cx } from '@emotion/css';

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(7),

    '@media screen and (max-device-width: 412px) ': {
      paddingBottom: theme.spacing(5),
    },
    '@media screen and (max-width: 440px) ': {
      paddingBottom: theme.spacing(5),
    },
  },
});

const PageTitleView = ({ children, classes, className }) => (
  <Grid item className={cx(classes.root, className)}>
    <Typography variant="h4">{children}</Typography>
  </Grid>
);

export const PageTitle = withStyles(PageTitleView, styles);
