import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { INTERCOM_APP_ID } from 'features/common/constants';

import { theme } from './theme';
import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';
import { NotificationProvider } from './features/notification';
import App from './app';

const rootContainer = document.querySelector('#root');
const store = configureStore({});

const intercomUser = {
  appId: INTERCOM_APP_ID,
  autoBoot: true,
};

const root = createRoot(rootContainer);

const render = () => {
  root.render(
    <IntercomProvider {...intercomUser}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <NotificationProvider>
                <App />
              </NotificationProvider>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntercomProvider>,
  );
};

if (module.hot) {
  module.hot.accept('./app', render);
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
