import { withStyles } from 'tss-react/mui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputBase, FormHelperText, Typography, Grid } from '@mui/material';

import { StatusSignUp } from '../constants';

const styles = theme => ({});

function ExpiredEmailTokenView({ classes, selfRegistrationLogin, resendToken, login, setLogin, status }) {
  return (
    <div>
      {status === StatusSignUp.SUCCESS_SENT ? (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography className={classes.marginRight} variant="h4">
              Email verification
            </Typography>
          </Grid>
          <Typography className={classes.titleSignIn} variant="body2">
            Email successfully sent, check your mail
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography className={classes.marginRight} variant="h4">
              Email verification
            </Typography>
          </Grid>
          <Typography className={classes.titleSignIn} variant="inherit">
            Email confirmation link has expired.
          </Typography>
        </Fragment>
      )}

      {!selfRegistrationLogin && (
        <Grid container className={classes.formControlSignIn}>
          <FormControl fullWidth margin="normal">
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="login"
              name="login"
              placeholder="Email"
              type="email"
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
      )}
      <Grid container alignItems="flex-start" justifyContent="center">
        <Button fullWidth className="normal" size="large" onClick={resendToken}>
          Send confirmation email again
        </Button>
      </Grid>
    </div>
  );
}

ExpiredEmailTokenView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ExpiredEmailToken = withStyles(ExpiredEmailTokenView, styles);
