import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link, InputBase, Typography, Paper, Grid, FormControl, CssBaseline } from '@mui/material';

import { ErrorDialog, DialogNotify } from 'ui';

import { sendTokenToEmail } from '../effects/send-token-to-email';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
    '@media screen and (max-device-width: 460px)': {
      '& h4': {
        fontSize: '24px',
      },
    },
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  grid: {
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    '&> span': {
      marginRight: '5px',
    },
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
});

const mapDispatchToProps = dispatch => ({
  sendTokenToEmail: email => dispatch(sendTokenToEmail, email),
});

function ForgetPasswordPageView({ classes, sendTokenToEmail }) {
  const navigate = useNavigate();
  const [ email, setEmail ] = useState('');

  const [ error, setError ] = useState('');
  const [ errorShow, setErrorShow ] = useState(false);

  const [ dialogNotifyShow, setDialogNotifyShow ] = useState(false);
  const [ dialogMessage, setDialogMessage ] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { ok, error } = await sendTokenToEmail(email);
    if (ok) {
      setDialogNotifyShow(true);
      setDialogMessage('Password reset link has been sent to your email');
    } else {
      setError(error.data);
      setErrorShow(true);
    }
  };
  const closeDialogNotify = () => {
    setDialogNotifyShow(false);
    navigate('/join');
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.container}>
        <Paper className={classes.paperSignIn}>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography className={classes.marginRight} variant="h4">
              Reset password
            </Typography>
          </Grid>

          <div className={classes.form}>
            <Grid container className={classes.formControlSignIn}>
              <FormControl fullWidth>
                <InputBase
                  autoFocus
                  required
                  autoComplete="Email"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputField,
                  }}
                  id="email"
                  name="email"
                  placeholder="Email that you used to register"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid container alignItems="flex-start" className={classes.formButton} justifyContent="center">
              <Button fullWidth className="normal" size="large" type="submit" onClick={handleSubmit}>
                Reset my password
              </Button>
            </Grid>
            <Grid container className={classes.grid}>
              <Link
                onClick={() => {
                  navigate('/join');
                }}
              >
                Go to login page
              </Link>
            </Grid>
          </div>
        </Paper>
        <Grid className={classes.text}>
          <Typography variant="body1" />
        </Grid>
      </div>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
      <DialogNotify closeTitle="Ok" handleClose={closeDialogNotify} open={dialogNotifyShow}>
        {dialogMessage}
      </DialogNotify>
    </main>
  );
}

ForgetPasswordPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ForgetPasswordPage = connect(null, mapDispatchToProps)(withStyles(ForgetPasswordPageView, styles));
