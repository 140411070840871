import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import React, { Fragment, useState, useEffect, forwardRef } from 'react';
import { Button, Link, Typography, Grid } from '@mui/material';

import { KYCResults } from '../../kyc/models';

const styles = theme => ({
  root: {
    width: '100%',
    cursor: 'default',
  },
  banner: {
    borderRadius: 0,
    width: '100%',
    padding: '16px 70px',
    background: '#00BDFF',
    boxShadow: 'unset',
    '@media screen and (max-device-width: 412px) ': {
      padding: '16px 16px',
    },
    '@media screen and (max-width: 440px) ': {
      padding: '16px 16px',
    },
  },
  text: {
    color: '#fff',
  },
  link: {
    color: '#000',
    cursor: 'pointer',
  },
  button: {
    width: 'auto',
  },
  marginText: {
    padding: '9px 0',
  },
  spannBut: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 'auto',
    marginRight: '-8px',
    paddingLeft: '16px',
    '@media screen and (max-device-width: 412px)': {
      paddingLeft: '8px',
      marginRight: 'unset',
    },
    '@media screen and (max-width: 412px)': {
      paddingLeft: '8px',
      marginRight: 'unset',
    },
  },
  rootT: {
    padding: '16px 70px',
    background: '#00BDFF',
    borderRadius: 0,
    width: '100%',
    alignItems: 'flex-start',
    boxShadow: 'unset',
  },
  link2: {
    textDecoration: 'underline',
    width: '100%',
    fontWeight: 600,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:link': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
    '&:active': {
      color: '#FFFFFF',
    },
  },
});

const KYCBannerView = forwardRef(({ classes, KYCStatus }, ref) => {
  const navigate = useNavigate();
  const [ isShow, setShow ] = useState(false);

  useEffect(() => {
    if (KYCStatus !== KYCResults.PASSED) {
      setShow(true);
    }
  }, [ KYCStatus ]);

  return (
    <Fragment>
      {isShow && (
        <div className={classes.root} ref={ref}>
          {[ KYCResults.NEW, KYCResults.RETRY ].includes(KYCStatus) && (
            <Grid
              container
              alignItems="center"
              className={classes.banner}
              justifyContent="space-between"
              wrap="nowrap"
              onClick={(event) => {
                event.stopPropagation();
                navigate('/account/kyc');
              }}
            >
              <Typography className={`${classes.text} body3`} variant="body2">
                Please complete KYC verification in order to have full access to the VNX Platform. Click on the banner to go to the KYC verification page.
              </Typography>
              <div className={`${classes.spannBut}`}>
                <Button
                  className={`${classes.button} white radiusLarge`}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate('/account/kyc');
                  }}
                >
                  Go to KYC
                </Button>
              </div>
            </Grid>
          )}
          {KYCStatus === KYCResults.REJECTED && (
            <Grid container alignItems="center" className={classes.banner} justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <Typography className={`${classes.text} body3`} variant="body2">
                  You did not pass KYC verification on the{' '}
                  <Link className={classes.link2} rel="noreferrer noopener" onClick={() => navigate('/account')}>
                    VNX Platform
                  </Link>{' '}
                  . If you have any questions, please contact{' '}
                  <Link
                    className={classes.link2}
                    onClick={() => {
                      window.Intercom('show');
                      return false;
                    }}
                  >
                    Compliance
                  </Link>
                </Typography>
              </Grid>
              <Grid item className={`${classes.spannBut}`}>
                <Button
                  className={`${classes.button} white radiusLarge`}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    window.Intercom('show');
                  }}
                >
                  Contact
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </Fragment>
  );
});

export const KYCBanner = withStyles(KYCBannerView, styles);
