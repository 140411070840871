import { logout } from '../effects/logout';
import { accountApi } from '../api';
import { getResponse } from '../../common/response';

export const register = (token, password) => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.create, token, {
      password,
      region: 'US',
    });

    const { ok, error, data } = getResponse(response);

    if (ok) {
      await dispatch(logout);
    }

    return { ok, error, data };
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};
