import { withStyles } from 'tss-react/mui';
import React from 'react';
import Grid from '@mui/material/Grid';
import { cx } from '@emotion/css';

const styles = theme => ({
  root: {
    width: '100%',
    background: '#fff',
    marginBottom: theme.spacing(10),
  },
});

const ContainerView = ({ children, classes, className }) => (
  <Grid container className={cx(classes.root, className)}>
    {children}
  </Grid>
);

export const Container = withStyles(ContainerView, styles);
