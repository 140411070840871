import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { cx } from '@emotion/css';

import { ReactComponent as InfoIcon } from '../assets/icons/Info.svg';

const styles = theme => ({
  icon: {
    cursor: 'pointer',
  },
  gray: {
    width: 18,
    height: 18,
    marginLeft: 9,
    '& path': {
      fill: '#9FA4AE',
    },
  },
  white: {
    width: 14,
    height: 14,
    marginLeft: 6,
    '& path': {
      fill: '#fff',
    },
  },
});

const InfoView = ({ classes, children, color }) => (
  <Tooltip
    arrow
    classes={{ arrow: 'arrow', tooltip: 'tooltip' }}
    enterTouchDelay={0}
    leaveTouchDelay={4000}
    title={
      <Typography align="center" variant="body2">
        {children}
      </Typography>
    }
  >
    <InfoIcon className={cx(classes.icon, color === 'white' ? classes.white : classes.gray)} />
  </Tooltip>
);

export const Info = withStyles(InfoView, styles);
