import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Tooltip as MaterialTooltip } from '@mui/material';

const styles = theme => ({
});

const TooltipView = ({ children, title, placement = 'bottom', ...props }) => (
  <MaterialTooltip {...props} arrow classes={{ arrow: 'arrow', tooltip: 'tooltip' }} placement={placement} title={title}>
    {children}
  </MaterialTooltip>
);

export const TooltipStyled = withStyles(TooltipView, styles);
