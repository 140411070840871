import { accountApi } from '../api';
import { getResponse } from '../../common/response';

export const changePassword = (token, password) => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.changePassword, token, {
      password,
    });

    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};
