import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Typography, Avatar } from '@mui/material';

import identify from 'assets/identify.svg';

const styles = {
  avatar: {
    marginRight: 18,
    width: '26px',
    height: '26px',
    backgroundColor: '#00BDFF',
    color: '#333',
    mixBlendMode: 'normal',
    borderRadius: '8px',
    cursor: 'pointer',
  },
};

const IdentifyIconLink = () => <img alt="Avatar" src={identify} />;

const LoginIconView = ({ client, classes }) => {
  const navigate = useNavigate();
  const AvatarName = ({ firstName, lastName }) => {
    let result = '';

    if (firstName && firstName.length > 0) {
      result = result + firstName[0].toUpperCase();
    }
    if (lastName && lastName.length > 0) {
      result = result + lastName[0].toUpperCase();
    }
    if (result === '') {
      return <IdentifyIconLink />;
    }
    return result;
  };

  return (
    <Avatar className={classes.avatar} variant="square" onClick={() => navigate('/account/information')}>
      <Typography className="caption1" variant="caption">
        {AvatarName({ ...client })}
      </Typography>
    </Avatar>
  );
};

export const LoginIcon = withStyles(LoginIconView, styles);
