import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchTransactions: initialFetching,
  fetchUpdateTransactions: initialFetching,
  fetchRefund: initialFetching,
  fetchCheckRefundPosition: initialFetching,
  transactions: [],
  filterTransactions: {},
  refundPosition: {},
};

const symbiote = {
  fetchTransactions: createFetching('fetchTransactions'),
  fetchUpdateTransactions: createFetching('fetchUpdateTransactions'),
  fetchRefund: createFetching('fetchRefund'),
  fetchCheckRefundPosition: createFetching('fetchCheckRefundPosition'),
  setAccount: (state, data) => ({ ...state, account: data }),
  setTransactions: (state, data) => ({ ...state, transactions: data }),
  setFilterTransactions: (state, data) => ({ ...state, filterTransactions: data }),
  setRefundPosition: (state, data) => ({ ...state, refundPosition: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'transactions');
