export const mapNotifications = items =>
  items.map(item => ({
    id: item.id,
    status: item.Status,
    title: item.Title,
    message: item.Message,
    push: item.PopupMessage,
    type: item.Type,
    createdAt: item.createdAt,
  }));
