import { get } from '../common/api';

import { api } from './../common/index';

export const accountApi = {
  create: (token, data) => api.post(`/register/${token}`, data),
  sendTokenToEmail: email => api.post('/change-password/send-email', email),
  sendTokenToConfirmationEmail: token => api.get(`/confirm-email/${token}`),
  resendTokenToConfirmationEmail: data => api.post('/confirm-email/resend', data),
  changePassword: (token, data) => api.post(`/change-password/${token}`, data),
  getEmail: token => api.get(`/change-password/verify/${token}`),
  getRegisterEmail: token => api.get(`/register/${token}`),
};

export const sessionApi = {
  me: () => get('/ui/nokyc/users/me'),
  create: loginData => api.post('/auth/sign-in', loginData),
  create2fa: loginData => api.post('/auth/2fa-sign-in', loginData),
  refresh: refreshData => api.post('/auth/refresh', refreshData),
  drop: logoutData => api.post('/auth/sign-out', logoutData),
  signUp: signUpData => api.post('/register/self', signUpData),
};
