import { useCallback, useEffect } from 'react';

export const useEventPushService = ({ eventPush, addEventPush }) => {
  const eventPushNotify = useCallback(
    (item) => {
      addEventPush(item);
    },
    [ addEventPush ]
  );

  useEffect(() => {
    eventPushNotify({ ...eventPush });
  }, [ eventPush, eventPushNotify ]);
};
