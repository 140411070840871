import { withStyles } from 'tss-react/mui';
import React from 'react';
import Typography from '@mui/material/Typography';
import { cx } from '@emotion/css';

const styles = theme => ({
  title: {
    '&.bigSpacing': {
      marginBottom: theme.spacing(10),
    },
    '&.smallSpacing': {
      marginBottom: theme.spacing(6),
    },
  },
});

const TitleView = ({ children, classes, style }) => (
  <Typography className={cx(classes.title, style)} variant="h4">
    {children}
  </Typography>
);

export const Title = withStyles(TitleView, styles);
