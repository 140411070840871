import React from 'react';

const SettingsPage = React.lazy(() => import('./settings-page'));

export const settingsRoutes = [
  {
    path: '/account/information',
    element: <SettingsPage />,
  },
];
