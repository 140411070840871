import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Skeleton, Badge, Box, Grid, Link, Typography } from '@mui/material';
import { cx } from '@emotion/css';

import logoUSDC from 'assets/icons/Usdc-coin.svg';
import GoldTokenLogo from 'assets/icons/goldlogo.svg';
import { ReactComponent as DiamondIcon } from 'assets/icons/Diamond.svg';

const styles = theme => ({
  shape: {
    '& .shapes': {
      animation: 'MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite',
    },
  },
  badge: {
    height: '1em',
    width: '1em',
    borderRadius: '2px',
    display: 'inline-block',
  },
  percent: {
    '& > .MuiBadge-badge': {
      fontSize: '10px',
      border: `2px solid ${theme.palette.background.paper}`,
      backgroundColor: 'black',
      color: 'white',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
    },
  },
  logo: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  logoDGR: {
    width: '32px',
    height: '32px',
    backgroundImage: `url(${GoldTokenLogo})`,
    backgroundSize: 'contain',
    borderRadius: '50%',
  },
  logoUSDC: {
    width: '32px',
    height: '32px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${logoUSDC})`,
    backgroundSize: 'contain',
    borderRadius: '50%',
  },
  currencyContainer: {
    gap: theme.spacing(3),
  },
});

/**
 *
 * @param {string} theme - white, auction, normal(default)
 */

const CurrencyView = ({
  classes,
  structured,
  symbolName,
  afterIcon = 'Euro',
  color = '#AE8E41', //5d4a1f
  icon = <DiamondIcon />,
  isEmpty = false,
  linkTo = {},
  isTransaction = true,
  logo,
}) => {
  const logotypes = { 'GOLD Structured Deposit': classes.logoDGR, 'USDC Structured Deposit': classes.logoUSDC };
  return (
    <Grid container alignItems="center" className={isEmpty ? classes.shape : ''} direction="row" wrap="nowrap">
      <Grid container item alignItems="center" className={classes.currencyContainer} justifyContent="flex-start" wrap="nowrap">
        {!isTransaction ? (
          <Grid item>
            <div className={cx(classes.badge, 'myAssetBadge')} style={{ backgroundColor: color }} />
          </Grid>
        ) : null}

        <Grid item>
          {!structured ? (
            logo ? (
              <img alt="Asset logo" className={classes.logo} src={logo} />
            ) : (
              <div className={classes.logo}>{icon}</div>
            )
          ) : (
            <Badge badgeContent="%" className={classes.percent} overlap="circular">
              {logo ? (
                <img alt="Asset logo" className={classes.logo} src={logo} />
              ) : (
                <Box className={logotypes[symbolName]} />
              )}
            </Badge>
          )}
        </Grid>
        <Grid item>
          {typeof linkTo === 'function' ? (
            <Link style={{ color: 'black' }} onClick={linkTo}>
              <Typography className="body3" variant="body2">
                {isEmpty ? <Skeleton height={20} variant="rectangular" width={36} /> : afterIcon}
              </Typography>
            </Link>
          ) : (
            <Typography className="body3" variant="body2">
              {isEmpty ? <Skeleton height={20} variant="rectangular" width={36} /> : afterIcon}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TableCurrency = withStyles(CurrencyView, styles);
