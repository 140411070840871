export const flatBackendValidationErrors = (error) => {
  let errors = {};
  error.data.forEach((item) => {
    if (item.context.key) {
      errors[item.context.key] = item.message;
    } else {
      const field = item.path[1];
      errors[field] = item.message;
    }
  });
  return errors;
};

export const flatValidationErrors = (errors) => {
  let errorsFlat = {};
  for (let error of errors) {
    const field = error.path.split('.').pop();
    if (field && error.errors.length !== 0) {
      errorsFlat[field] = error.errors[0];
    }
  }
  return errorsFlat;
};

/**
 * Filter for onChange input
 * @param {string} value Input value
 * @param {RegExp} regExp RegExp for filter value string
 * @param {Function} cb Callback function when get result
 */
export const filterInput = (value, regExp, cb) => {
  let filteredValue = value.replace(regExp, '');
  cb(filteredValue);
};

export const allowSymRegExp = /[^A-Za-z0-9\s^%@#$!?{}()|:,./\-+[]]*/g;
export const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const urlRegExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const allowNumberRegExp = /[^0-9\s^%@#$!?{}()|:,./\-+[]]*/g;
export const REGEXP_NUM = /^[0-9](\d+)?(,\d{1,2})?$|^$/;
