import { withStyles } from 'tss-react/mui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { cx } from '@emotion/css';

import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg';

const styles = (theme, { noMargin }) => ({
  root: {
    marginLeft: !noMargin ? theme.spacing(2) : 0,
  },
  copy: {
    height: 20,
    width: 20,
    cursor: 'pointer',
    '& path': {
      fill: '#9FA4AE',
    },
  },
  inside: {
    position: 'absolute',
    height: '32px',
    width: '50px',
    right: '8px',
    top: '8px',
    '& path': {
      fill: '#9FA4AE',
    },
  },
});

const CopyClipboardView = ({ text, classes, inside = false, children, noMargin = false, placement='top-end', fullWidth = false }) => {
  const [ isCopy, setIsCopy ] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 1500);
    }
  }, [ isCopy ]);
  return (
    <Grid container alignItems='center' className={inside ? cx( classes.inside, classes.root) : classes.root } justifyContent={inside ? 'flex-end' : 'initial'} wrap="nowrap">
      <CopyToClipboard
        text={`${text}`}
        onCopy={() => {
          setIsCopy(true);
        }}
      >
        <Tooltip arrow classes={{ arrow: 'arrow', tooltip: 'tooltip' }} placement={placement} style={ fullWidth ? { width: '100%' } : {}} title={isCopy ? 'Copied' : 'Click to copy'}>
          {children ? children : <CopyIcon className={classes.copy} /> }
        </Tooltip>
      </CopyToClipboard>
    </Grid>
  );
};

export const CopyClipboard = withStyles(CopyClipboardView, styles);
