import { withStyles } from 'tss-react/mui';
import React from 'react';
import PropTypes from 'prop-types';
import { green, amber } from '@mui/material/colors';
import { IconButton, Typography, SnackbarContent, Snackbar } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { cx } from '@emotion/css';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, show, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      action={[
        <IconButton aria-label="Close" className={classes.close} color="inherit" key="close" size="large" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      aria-describedby="client-snackbar"
      className={cx(classes[variant], className)}
      message={
        <span className={classes.message} id="client-snackbar">
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {Array.isArray(message) ? (
            message.map((error, index) => (
              <Typography className={classes.message} key={index} variant="subtitle1">
                {error.message}
              </Typography>
            ))
          ) : (
            <Typography className={classes.message} variant="subtitle1">
              {message}
            </Typography>
          )}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([ 'success', 'warning', 'error', 'info' ]).isRequired,
};

const MySnackbarContentWrapper = withStyles(MySnackbarContent, styles1);

const ErrorSnackbarView = ({ variant, message, show, handleClose }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    autoHideDuration={6000}
    open={show}
    onClose={() => handleClose(!show)}
  >
    <MySnackbarContentWrapper message={message} variant={variant} onClose={() => handleClose(!show)} />
  </Snackbar>
);

export const ErrorSnackbar = ErrorSnackbarView;
