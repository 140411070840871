import { withStyles } from 'tss-react/mui';
import { useDispatch, connect } from 'react-redux';
import React, { forwardRef, useEffect } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import { Snackbar } from 'ui';
import { SocketService } from 'services/socket-service';
import { getKYCStatus } from 'features/kyc/status';
import { KYCResults } from 'features/kyc/models';
import { getKycBannerSizeSelector } from 'features/common/selectors';
import { WIDTH_PANEL } from 'features/common/constants';
import { authenticatedSelector, authLoadingStatusSelector } from 'features/auth/selectors';
import { kycReportSelector } from 'features/account/selectors';

import { markReadNotification } from '../effects';

const styles = theme => ({
  topDense: {
    top: '-4px !important',
    left: `calc(${WIDTH_PANEL}px - 0px) !important`,
    '@media screen and (max-device-width: 412px) ': {
      top: '48px !important',
      left: '-4px !important',
    },
    '@media screen and (max-width: 440px) ': {
      top: '48px !important',
      left: '-4px !important',
    },
    '@media screen and (max-width: 899px) ': {
      top: '48px !important',
      left: '-4px !important',
    },
  },
});

const mapStateToProps = state => ({
  KYCReport: kycReportSelector(state),
  kycBannerSize: getKycBannerSizeSelector(state),
  authenticated: authenticatedSelector(state),
  loading: authLoadingStatusSelector(state) !== 2,
});

const mapDispatchToProps = dispatch => ({});

const NotificationProviderInner = ({ children, classes, KYCReport, kycBannerSize, authenticated, loading }) => {
  const notistackRef = React.createRef();
  const KYCStatus = getKYCStatus(KYCReport);
  const dispatch = useDispatch();

  const handleClose = (id) => {
    dispatch(markReadNotification, id);
  };

  useEffect(() => {
    if (!loading && !authenticated) {
      notistackRef.current.closeSnackbar();
    }
  }, [ authenticated, loading, notistackRef ]);

  return (
    <NotistackProvider
      dense
      Components={{
        default: forwardRef((notification, ref) => (
          <div ref={ref}>
            <Snackbar
              KYCStatus={KYCStatus} full={true} key={notification.key} notification={notification} notistackRef={notistackRef} onClose={handleClose} />
          </div>
        )),
      }}
      TransitionProps={{ direction: 'down' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      autoHideDuration={null}
      classes={{ containerRoot: classes.topDense }}
      maxSnack={5}
      ref={notistackRef}
      style={KYCStatus !== KYCResults.PASSED ? { top: `${(kycBannerSize && kycBannerSize.height + 4) || 72}px` } : {}}
    >
      <SocketService />
      {children}
    </NotistackProvider>
  );
};

export const NotificationProvider = connect(mapStateToProps, mapDispatchToProps)(withStyles(NotificationProviderInner, styles));
