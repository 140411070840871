import React from 'react';

const ApiKeysPage = React.lazy(() => import('./api-keys-page'));

export const apiKeysRoutes = [
  {
    path: '/account/keys',
    element: <ApiKeysPage />,
  },
];
