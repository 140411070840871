import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Grid, Button, CssBaseline } from '@mui/material';

import { PageTitle } from 'ui';

import notFound from '../../assets/404.svg';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#FFF',
  },
  paper: {
    flex: '1',
    width: '100%',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10) * 2,
    paddingLeft: 70,
    paddingRight: 70,
  },
  header: {
    height: '100%',
  },
  body: {
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const NotFoundPageView = ({ classes }) => {
  const navigate = useNavigate();
  return (
    <main className={classes.root}>
      <CssBaseline />

      <Grid container className={classes.paper} direction="column">
        <Grid container item className={classes.header} justifyContent="space-between">
          <PageTitle>Page not found</PageTitle>
        </Grid>
        <Grid container item className={classes.body}>
          <img alt="Logo" src={notFound} />
        </Grid>
        <Grid container item className={classes.buttons}>
          <Button
            className="normal"
            size="large"
            type="submit"
            onClick={() => {
              navigate('/');
            }}
          >
            Back to root
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};

export const NotFoundPage = withStyles(NotFoundPageView, styles);
