import { withStyles } from 'tss-react/mui';
import React from 'react';
import { DialogTitle, DialogContent, Typography, Grid, Button } from '@mui/material';

import { Modal } from './modal';

const styles = theme => ({
  description: {
    paddingBottom: theme.spacing(4),
  },
  button: {},
  buttonClose: {},
});

const DialogNotifyView = ({ classes, handleClose, children, open, dialogTitle = 'Notification', closeTitle = 'Close' }) => (
  <Modal handleClose={handleClose} open={open} title={dialogTitle}>
    <DialogTitle component="div">
      <Typography variant="h4">{dialogTitle}</Typography>
    </DialogTitle>
    <DialogContent>
      <Grid className={classes.description}>
        <Typography c variant="body2">
          {children}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Button fullWidth className="white" size="large" onClick={handleClose}>
          {closeTitle}
        </Button>
      </Grid>
    </DialogContent>
  </Modal>
);

export const DialogNotify = withStyles(DialogNotifyView, styles);
