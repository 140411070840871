import { combineReducers } from 'redux';

import { reducer as addresses } from './features/withdrawal-addresses';
import { reducer as wallet } from './features/wallet';
import { reducer as transactions } from './features/transactions';
import { reducer as structuredProduct } from './features/structured-product';
import { reducer as users } from './features/settings/information';
import { reducer as settings } from './features/settings';
import { reducer as offering, reducer as offerings } from './features/offering/';
import { reducer as notification } from './features/notification';
import { reducer as kyc } from './features/kyc';
import { reducer as disclosure } from './features/disclosure';
import { reducer as common } from './features/common';
import { reducer as auth } from './features/auth';
import { reducer as apiKeys } from './features/api-keys';
import { reducer as account } from './features/account';
import { reducer as twoFactor } from './features/2fa';

export const createReducer = () =>
  combineReducers({
    common,
    auth,
    offering,
    structuredProduct,
    account: combineReducers({
      account,
      transactions,
      offerings,
      users,
    }),
    kyc,
    settings,
    addresses,
    apiKeys,
    wallet,
    twoFactor,
    notification,
    disclosure,
  });
