import React from 'react';

const NotificationCenterPage = React.lazy(() => import('./notification-center-page'));

export const notificationCenterRoutes = [
  {
    path: '/account/notifications',
    element: <NotificationCenterPage />,
  },
];
