import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { useInterval } from 'lib/timer';
import { RoleClientType } from 'features/common/models';
import { getAllSymbols, getCryptoBlockchains, getPublicAllSymbols } from 'features/common/effects';
import { getClient, getClientSymbolsMyAssets } from 'features/account/effects';

import { getKYCStatus } from '../kyc/status';
import { KYCResults } from '../kyc/models';
import { allSymbolsSelector } from '../common/selectors';
import { kycReportSelector } from '../account/selectors';
import { Spinner } from '../../ui';

import { authenticatedSelector, authClientClientTypeSelector, authLoadingStatusSelector } from './selectors';
import { checkAuthenticated } from './effects/check-authenticated';
import { IdleTimeout } from './components';

const mapStateToProps = state => ({
  authenticated: authenticatedSelector(state),
  clientType: authClientClientTypeSelector(state),
  KYCReport: kycReportSelector(state),
  allSymbols: allSymbolsSelector(state),
  loading: authLoadingStatusSelector(state) !== 2,
});
const mapDispatchToProps = dispatch => ({
  checkAuthenticated: () => dispatch(checkAuthenticated),
  getClient: () => dispatch(getClient),
  getAllSymbols: () => dispatch(getAllSymbols),
  getPublicAllSymbols: () => dispatch(getPublicAllSymbols),
  getCryptoBlockchains: () => dispatch(getCryptoBlockchains),
  getClientSymbolsMyAssets: nokyc => dispatch(getClientSymbolsMyAssets, nokyc),
});

const refreshTime = 5 * 60 * 1000; // 5 min
const signUpPagePath = '/join';
const accountPagePath = '/account';
const rootPath = '/';

const useCheckKYCStatusForNotPassedUser = (checkAuthenticated, KYCReport) => {
  const [ timer, setTimer ] = useState(null);
  const KYCStatus = getKYCStatus(KYCReport);

  useEffect(() => {
    if (KYCStatus !== KYCResults.PASSED) {
      if (!timer) {
        setTimer(refreshTime);
      }
    } else {
      if (timer) {
        setTimer(null);
      }
    }
  }, [ KYCStatus, timer ]);

  useInterval(checkAuthenticated, timer);
};

const AccountLoaderContainer = ({
  children,
  authenticated,
  checkAuthenticated,
  loading,
  KYCReport,
  clientType,
  getClient,
  getAllSymbols,
  getPublicAllSymbols,
  getCryptoBlockchains,
  getClientSymbolsMyAssets,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = useCallback(
    (path) => {
      navigate(path);
    },
    [ ]
  );
  const KYCStatus = getKYCStatus(KYCReport);

  const fetchCheckAuthenticated = useCallback(async () => {
    if (authenticated) {
      await checkAuthenticated();
    }
  }, [ authenticated, checkAuthenticated ]);

  const redirectToAccountPage = useCallback(() => {
    if (clientType && [ RoleClientType.INVESTOR, RoleClientType.ISSUER, RoleClientType.BROKER ].includes(clientType)) {
      const searchParams = new URLSearchParams(window.location.search);
      const isNotForcedRedirect = !searchParams.get('redirect');
      if (isNotForcedRedirect && (location.pathname === rootPath || (location.pathname === signUpPagePath && authenticated))) {
        navigate(accountPagePath);
      } else if (!isNotForcedRedirect) {
        navigate(searchParams.get('redirect'));
      }
    }
  }, [ authenticated, clientType, location.pathname ]);

  const checkUser = useCallback(async () => {
    const { authenticated } = await checkAuthenticated();
    if (!authenticated) {
      const currentPath = window.location.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      const redirect = searchParams.get('redirect');
      if (currentPath === accountPagePath) {
        redirectTo(signUpPagePath);
      } else if (currentPath === rootPath) {
        redirectTo(signUpPagePath);
      } else if (currentPath !== signUpPagePath && !redirect) {
        redirectTo(`${signUpPagePath}?redirect=${window.location.pathname}`);
      }
    }
  }, [ checkAuthenticated, redirectTo ]);

  useEffect(() => {
    if (!authenticated) {
      checkUser();
    } else if (authenticated) {
      getClient();
      redirectToAccountPage();
    }
  }, [ authenticated, getClient, checkUser, redirectToAccountPage ]);

  useEffect(() => {
    if (KYCStatus !== KYCResults.IDLE) {
      const fetchSymbols = async () => {
        if (KYCStatus === KYCResults.PASSED) {
          await getAllSymbols();
        } else {
          await getPublicAllSymbols();
        }
      };
      getClientSymbolsMyAssets(KYCStatus !== KYCResults.PASSED);
      fetchSymbols();
    }
  }, [ getClientSymbolsMyAssets, getPublicAllSymbols, getAllSymbols, KYCStatus ]);

  useEffect(() => {
    const fetchBlockchains = async () => {
      await getCryptoBlockchains();
    };
    fetchBlockchains();
  }, [ getCryptoBlockchains ]);

  useCheckKYCStatusForNotPassedUser(fetchCheckAuthenticated, KYCReport);

  if (loading && !authenticated) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <IdleTimeout />
      {children}
    </Fragment>
  );
};

export const AccountLoader = connect(mapStateToProps, mapDispatchToProps)(AccountLoaderContainer);
