import { api } from 'features/common/index';

export const transactionsApi = {
  getTransactions: query => api.get('/ui/transactions', query),
  getPublicTransactions: query => api.get('/ui/nokyc/transactions', query),
  refund: query => api.post('/ui/crypto/refund', query),
  refundNoKyc: query => api.post('/ui/nokyc/crypto/refund', query),
  checkRefundPositions: query => api.get('/ui/crypto/refund/check-positions', query),
  checkRefundPositionsNoKyc: query => api.get('/ui/nokyc/crypto/refund/check-positions', query),
};
