import React from 'react';

const WithdrawalAddressesPage = React.lazy(() => import('./withdrawal-addresses-page'));

export const withdrawalAddressesRoutes = [
  {
    path: '/account/addresses',
    element: <WithdrawalAddressesPage />,
  },
];
