import { withStyles } from 'tss-react/mui';
import React from 'react';
import Badge from '@mui/material/Badge';
import { cx } from '@emotion/css';

import { ReactComponent as NotificationIcon } from 'assets/icons/Notification.svg';

const RingIcon = withStyles(({ classes, onClick, isActive, hasNotifications }) => (
  <div className={cx(classes.root, isActive ? classes.active : '')} onClick={onClick}>
    <Badge classes={{ badge: cx(classes.badge, isActive ? classes.badgeActive : '') }} invisible={!hasNotifications} overlap="rectangular" variant="dot">
      <NotificationIcon className={cx(hasNotifications ? classes.notification : '', isActive ? classes.notificationActive : '')} height={20} width={20} />
    </Badge>
  </div>
), {
  root: {
    width: '26px',
    height: '26px',
    background: 'transparent',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  badge: {
    height: '10px',
    minWidth: '10px',
    borderRadius: '5px',
    top: '20%',
    right: 4,
    border: '2px solid #F4F5F6',
    backgroundColor: '#ED2939',
  },
  badgeActive: {
    border: '2px solid #000',
  },
  notification: {
    transform: 'rotate(45deg)',
  },
  notificationActive: {
    '& path': {
      fill: '#FFF',
    },
  },
  active: {
    background: '#000000',
  },
});

export { RingIcon };
