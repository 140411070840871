import { useCallback, useEffect, useRef, useState } from 'react';

import { isJsonString } from 'lib/string';

function parse(data) {
  const { wsd, wsc } = JSON.parse(data);
  return { action: wsc, data: wsd };
}

export const useWebsocket = () => {
  const [ pureMessage, setPureMessage ] = useState({});
  const [ parsedMessage, setParsedMessage ] = useState({});
  const [ socketStatus, setSocketStatus ] = useState('closed');
  let socket = useRef();
  let timeout = useRef(250);

  const closeConnection = useCallback(() => {
    clearTimeout(socket.current?.connectInterval);
    cleanListeners();
    socket.current && socket.current.close(1000, 'close connection');
    setSocketStatus('closed');
    console.warn('Connection is close');
  }, [ cleanListeners ]);

  const openHandler = useCallback(() => {
    clearTimeout(socket.current?.connectInterval);
    console.warn('[open] Connection established');
    setSocketStatus('open');
  }, []);

  const closeHandler = useCallback((event) => {
    switch (event.code) {
      case 1000: // CLOSE_NORMAL
        console.warn('[close] Connection closed');
        closeConnection();
        break;
      default:
        // Abnormal closure
        reconnect(event);
        break;
    }
  }, [ closeConnection, reconnect ]);

  const messageHandler = useCallback((event) => {
    setPureMessage(event);
    setParsedMessage(parse(event.data));

    console.warn(`[message] Data received from server: ${event.data}`);
  }, []);

  const errorHandler = useCallback((error) => {
    switch (error.code) {
      case 'ECONNREFUSED':
        reconnect(error);
        break;
      default:
        console.error(`[error] ${error.message}`);
        break;
    }
    closeConnection();
  }, [ reconnect, closeConnection ]);

  const cleanListeners = useCallback(() => {
    socket.current?.removeEventListener('open', openHandler);
    socket.current?.removeEventListener('message', messageHandler);
    socket.current?.removeEventListener('close', closeHandler);
    socket.current?.removeEventListener('error', errorHandler);
  }, [ closeHandler, errorHandler, messageHandler, openHandler ]);

  const createConnection = useCallback(() => {
    cleanListeners();
    socket.current?.close();
    const accessToken = JSON.parse(isJsonString(localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : '""');
    const appDeviceId = JSON.parse(isJsonString(localStorage.getItem('appDeviceId')) ? localStorage.getItem('appDeviceId') : '""');
    if (accessToken && appDeviceId) {
      socket.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_HOST}/api/ws?token=${accessToken}&appDeviceId=${appDeviceId}`);
    } else {
      socket.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_HOST}/api/ws/`);
    }

    socket.current.addEventListener('open', openHandler);
    socket.current.addEventListener('message', messageHandler);
    socket.current.addEventListener('close', closeHandler);
    socket.current.addEventListener('error', errorHandler);
  }, [ cleanListeners, closeHandler, openHandler, errorHandler, messageHandler ]);

  const reconnect = useCallback((event) => {
    setSocketStatus('pending');
    const maxTimeout = 60000;
    const maxAttemptTimeout = 10000;
    const second = 1000;

    timeout.current += timeout.current;
    const lastAttemptTimeout = timeout.current < maxAttemptTimeout ? maxAttemptTimeout / second : maxTimeout / second;
    const attemptTimeout = timeout.current / second;
    console.warn(`Socket is closed. Reconnect will be attempted in ${Math.min(lastAttemptTimeout, attemptTimeout)} second.`, event);

    if (timeout.current > maxAttemptTimeout) {
      timeout.current = maxTimeout;
    }

    socket.current.connectInterval = setTimeout(createConnection, Math.min(maxTimeout, timeout.current));
  }, [ timeout, createConnection ]);

  useEffect(() => {
    window.addEventListener('beforeunload', closeConnection);
    return () => {
      window.removeEventListener('beforeunload', closeConnection);
    };
  }, [ closeConnection ]);

  return { pureMessage, parsedMessage, createConnection, closeConnection, socketStatus };
};
