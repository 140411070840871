import React from 'react';

const TransactionsPage = React.lazy(() => import('./transactions-page'));

export const transactionsRoutes = [
  {
    path: '/account/transactions',
    element: <TransactionsPage indexTab={0} />,
  },
];
