import GA4React  from 'react-ga4';
import { useEffect } from 'react';

import { PageTitles } from 'features/common/models';

const getPageTitle = page => PageTitles[page] || (Number(page.split('/')[2]) ? `${page.split('/')[2]}/Product Info` : page);

export const trackPage = (page) => {
  GA4React.set({ page });
  GA4React.send({ hitType: 'pageview', page, title: getPageTitle(page) });
};

export const useGoogleAnalyticsHistoryListen = (location) => {
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === 'production' || process.env.REACT_APP_PLATFORM === 'prod' || process.env.REACT_APP_PLATFORM === 'uat') {
      if (process.env.REACT_APP_GOOGLE_ANALYST !== '') {
        trackPage(location.pathname);
      }
    }
  }, [ location ]);
};
