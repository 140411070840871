import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { theme } from '../../../theme';

import { NotificationDetails } from './notification-details';

export const NotificationsList = ({ items, handleMarkRead }) => {
  if (items.length) {
    let readChildren = [];
    let newChildren = [];
    items &&
      items.forEach((item) => {
        if (item.status === 'new') {
          newChildren.push(<NotificationDetails item={item} key={item.id} onMarkRead={handleMarkRead} />);
        } else {
          readChildren.push(<NotificationDetails item={item} key={item.id} onMarkRead={handleMarkRead} />);
        }
      });
    return (
      <Grid container>
        <Grid container style={{ marginBottom: theme.spacing(7) }}>
          {newChildren}
        </Grid>
        <Grid container>{readChildren}</Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Typography variant="inherit">You have no notifications yet</Typography>
    </Grid>
  );
};
