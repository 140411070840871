import { accountApi } from '../api';
import { getResponse } from '../../common/response';

export const sendTokenToEmail = email => async (dispatch) => {
  try {
    const response = await dispatch(accountApi.sendTokenToEmail, {
      email,
    });
    return getResponse(response);
  } catch (error) {
    return { ok: false, error: { data: String(error), type: 'unknown_error' } };
  }
};
