export const cryptoWalletBlockColumns = [
  {
    name: 'name',
    title: 'Address label',
    width: '200px',
  },
  {
    name: 'blockchainType',
    title: 'Currency / Blockchain',
  },
  {
    name: 'walletAddress',
    title: 'Wallet address/IBAN',
  },
  {
    name: 'memo',
    title: 'Memo',
  },
  {
    name: 'destination',
    title: 'Hosted/Unhosted',
  },
  {
    name: 'status',
    title: 'Status',
  },
];

export const AddressTypes = {
  BANK: 'Bank',
  CRYPTO: 'Crypto',
};
export const OTHER_VASP = 'Other';

export const WalletMode = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const AddressStatusView = {
  'Waiting': 'Please confirm in your email',
};
