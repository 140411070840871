import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchOne: initialFetching,
  fetchKeys: initialFetching,
  fetchGenerateKey: initialFetching,
  fetchPublicKey: initialFetching,
  keyList: [],
  publicKey: {},
  generatedKey: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchKeys: createFetching('fetchKeys'),
  fetchGenerateKey: createFetching('fetchGenerateKey'),
  fetchPublicKey: createFetching('fetchGenerateKey'),
  setKeyList: (state, data) => ({ ...state, keyList: data }),
  setKey: (state, data) => ({ ...state, publicKey: data }),
  setGeneratedKey: (state, data) => ({ ...state, generatedKey: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'settings');
