import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchUsers: initialFetching,
  users: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  setUsers: (state, data) => ({ ...state, users: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'users');
