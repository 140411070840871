import React from 'react';
import Dialog from '@mui/material/Dialog';

export const Modal = ({ handleClose, open, children, fullWidth = true, maxWidth = 'sm', classes, PaperProps = {}, scroll = 'paper', onKeyDown = () => {} }) => (
  <div>
    <Dialog
      PaperProps={PaperProps}
      aria-labelledby="form-dialog-title"
      classes={classes}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={!!open}
      scroll={scroll}
      onClose={handleClose}
      onKeyDown={onKeyDown}
    >
      {children}
    </Dialog>
  </div>
);
