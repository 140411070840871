import { createSelector } from 'reselect';

export const commonRootSelector = createSelector(
  root => root.common,
  common => common
);

export const getNavigationSelector = createSelector(commonRootSelector, common => common.navigation);
export const getOpenTreesSelector = createSelector(commonRootSelector, common => common.openTrees);

export const allSymbolsSelector = createSelector(commonRootSelector, common => common.symbols);

export const getWindowSizeSelector = createSelector(commonRootSelector, common => common.windowSize);
export const getKycBannerSizeSelector = createSelector(commonRootSelector, common => common.kycBannerSize);

export const DGRPositionProductsSelector = createSelector(commonRootSelector, common => common.DGRPositionProducts);
export const FRTPositionProductsSelector = createSelector(commonRootSelector, common => common.FRTPositionProducts);
export const structuredDepositPositionProductsSelector = createSelector(commonRootSelector, common => common.structuredDepositPositionProducts);

export const positionsProductOnlyLoadingSelector = createSelector(commonRootSelector, common => common.fetchPositionsProductOnly.status);

export const cryptoBlockchainsSelector = createSelector(commonRootSelector, common => common.cryptoBlockchains);

export const cryptoBlockchainsLoadingSelector = createSelector(commonRootSelector, common => common.fetchCryptoBlockchains.status);

export const allTermsSelector = createSelector(commonRootSelector, common => common.allTerms);
