import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOneCode: initialFetching,
  data: [],
};

const symbiote = {
  fetchOneCode: createFetching('fetchOneCode'),
  setSecretKey: (state, data) => ({ ...state, data: data }),
  resetSecretKey: () => [],
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'twoFactor');
