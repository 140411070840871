import { withStyles } from 'tss-react/mui';
import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Tabs as MaterialTabs, Tab, Tooltip, AppBar } from '@mui/material';

import { Spinner } from './spinner';

const styles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    paddingBottom: theme.spacing(8),
    '@media screen and (max-device-width: 412px) ': {
      paddingBottom: theme.spacing(6),
    },
    '@media screen and (max-width: 440px) ': {
      paddingBottom: theme.spacing(6),
    },
  },
  tabsIndicator: {},
  container: {},
  tab: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  butt: {
    display: 'inline-flex',
    position: 'static',
    width: 'auto',
    height: '26px',
    fontSize: 18,
    borderRadius: '4px',
    fontFamily: 'Monument Extended',
    fontWeight: 400,
    letterSpacing: '0.05em',
    lineHeight: '32px',
    padding: '6px 12px',
    whiteSpace: 'nowrap',
    minWidth: '72px',
    flexGrow: 1,
    flexShrink: 0,
    textAlign: 'center',
    margin: '3px 0px',
    border: '2px solid transparent',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    alignItems: 'center',
    cursor: 'default',
    userSelect: 'none',
    // letterSpacing: 'normal',
    '@media screen and (max-device-width: 412px) ': {
      fontSize: 10,
      padding: '4px',
    },
    '@media screen and (max-width: 440px) ': {
      fontSize: 10,
      padding: '4px',
    },
  },
  buttText: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#E3E3E3',
  },
});

const TabsView = ({ classes, tabs, themeType, handleChange, value, outletContext }) => {
  const handleOnChange = (event, val) => {
    handleChange(val);
  };

  return (
    <div className={classes.root}>
      <AppBar classes={{ root: classes.appBar }} position="static">
        <MaterialTabs
          classes={{
            indicator: classes.tabsIndicator,
            flexContainer: classes.container,
            root: classes.tab,
          }}
          value={value}
          onChange={handleOnChange}
        >
          {tabs.map((tab, idx) => {
            if (tab.tooltip && tab.disabled) {
              return (
                <Tooltip
                  //disableTriggerFocus={true}
                  arrow
                  classes={{ arrow: 'arrow', tooltip: 'tooltip' }}
                  key={idx}
                  placement="top-end"
                  title={tab.tooltip}
                >
                  <div className={classes.butt}>
                    <span className={classes.buttText}>{tab.name}</span>
                  </div>

                  {/* <Tab
                    disableRipple
                    disableFocusRipple
                    key={idx.toString()}
                    classes={{root: classes.tab, selected: classes.selected}}
                    label={tab.name}
                    disabled={tab.disabled}
                  /> */}
                </Tooltip>
              );
            } else {
              return (
                <Tab
                  disableFocusRipple
                  disableRipple
                  classes={{ root: classes.tab, selected: classes.selected }}
                  disabled={tab.disabled}
                  key={idx.toString()}
                  label={tab.name}
                />
              );
            }
          })}
        </MaterialTabs>
      </AppBar>
      <Suspense fallback={<Spinner />}>
        <Outlet context={outletContext} />
      </Suspense>
      {/* {tabs.map((tab, idx) => value === idx && <TabContainer key={idx.toString()}>{tab.component}</TabContainer>)} */}
    </div>
  );
};

export const Tabs = withStyles(TabsView, styles);
