import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useState, Fragment, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, InputBase, Typography, Paper, Link, InputLabel, Grid, FormHelperText, FormControl, CssBaseline } from '@mui/material';
import { load } from '@fingerprintjs/fingerprintjs';

import { ErrorDialog } from 'ui';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTitle } from 'features/common/hooks';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo-black.svg';

import { auth2FASelector } from '../selectors';
import { resendTokenToConfirmation } from '../effects/resend-token-to-confirmation';
import { join2fa } from '../effects/join2fa';
import { join } from '../effects/join';
import { StatusSignUp } from '../constants';
import { SuccessRegister } from '../components';
import { AccountLoader } from '../account-loader';
import { InputCodeTwoFactor } from '../../2fa/components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
    '@media screen and (max-device-width: 460px)': {
      '& h4': {
        fontSize: '22px',
      },
    },
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  grid: {
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    '& > p': {
      marginRight: '5px',
    },
  },
  subTitle: {
    color: '#9FA4AE',
  },
  link: {
    alignItems: 'center',
    marginTop: '20px',
  },
});

const mapStateToProps = state => ({
  auth2fa: auth2FASelector(state),
});

const mapDispatchToProps = dispatch => ({
  onLogin: loginData => dispatch(join, loginData),
  onLogin2fa: loginData => dispatch(join2fa, loginData),
  resendTokenToConfirmation: data => dispatch(resendTokenToConfirmation, data),
});

function SignInPageView({ classes, onLogin, onLogin2fa, auth2fa, resendTokenToConfirmation }) {
  const navigate = useNavigate();
  useTitle('Sign In');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ emailPasswordError, setEmailPasswordError ] = useState('');
  const [ isError, setIsError ] = useState(false);

  const [ error, setError ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);
  const [ status, setStatus ] = useState('');
  const [ login, setLogin ] = useState('');
  const [ code, setCode ] = useState('');

  const [ , setAppDeviceId ] = useLocalStorage('appDeviceId', '');
  const [ , setTimeZone ] = useLocalStorage('timeZone', '');

  useEffect(() => {
    const fpPromise = load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      setAppDeviceId(result.visitorId);
      setTimeZone(result.components.timezone.value);
    })();
  }, [ setAppDeviceId, setTimeZone  ]);

  const clearError = () => {
    setEmailPasswordError('');
    setIsError(false);
  };

  const isDisabled = useMemo(() => (!email || !password ? true : false), [ email, password ]);

  const resendToken = async () => {
    if (email || login) {
      const { ok, errors } = await resendTokenToConfirmation({ login: email || login });
      if (ok) {
        setStatus(StatusSignUp.SUCCESS_SENT);
      } else {
        setError(errors.data);
        setErrorShow(true);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { ok, error } = await onLogin({
      login: email,
      password: password,
      code: code,
    });

    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get('redirect');
    if (!ok)  {
      if (error.type === 'wrong_email_or_password') {
        setEmailPasswordError('Wrong email or password');
        setIsError(true);
      } else if (error.type === 'invalid_credentials') {
        setEmailPasswordError('Wrong email or password');
        setIsError(true);
      } else if (error.type === StatusSignUp.CONFIRMATION_REQUIRED) {
        setStatus(StatusSignUp.CONFIRMATION_REQUIRED);
      } else if (error.type === '2fa_required') {
        setStatus(StatusSignUp.TWO_FACTOR_AUTH);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    }
  };

  const handleContinue = async (event) => {
    event.preventDefault();
    const { ok, error } = await onLogin2fa({
      login: email,
      password: password,
      code: code,
    });

    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get('redirect');
    if (!ok) {
      if (error.type === 'wrong_email_or_password') {
        setError('Wrong email or password or code');
        setIsError(true);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <AccountLoader>
        <Fragment>
          {status === StatusSignUp.CONFIRMATION_REQUIRED ? (
            <div className={classes.container}>
              <Paper className={classes.paperSignIn}>
                <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
                  <Typography variant="h4">Your email is not verified</Typography>
                </Grid>
                <Typography className={classes.formControlSignIn} variant="body2">
                  You can’t login to the VNX Platform until you confirm your email by following the instructions from the confirmation email we’ve sent to you
                  earlier.
                </Typography>
                <Grid container justifyContent="center">
                  <Button className="normal" onClick={resendToken}>
                    Send confirmation email again
                  </Button>
                </Grid>
              </Paper>
            </div>
          ) : status === StatusSignUp.SUCCESS_SENT ? (
            <div className={classes.container}>
              <Paper className={classes.paperSignIn}>
                <SuccessRegister classes={classes} login={login} resendToken={resendToken} selfRegistrationLogin={email} setLogin={setLogin} status={status} />
              </Paper>
            </div>
          ) : status === StatusSignUp.TWO_FACTOR_AUTH ? (
            <div className={classes.container}>
              <InputCodeTwoFactor
                code={code}
                error={error}
                handleContinue={handleContinue}
                isError={isError}
                setCode={setCode}
                title="Enter the verification code generated by Google Authenticator app your phone."
              />
            </div>
          ) : (
            <div className={classes.container}>
              <Paper className={classes.paperSignIn}>
                <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
                  <Typography className="" variant="h4">
                    Sign In
                  </Typography>
                  <LogoIcon height={20} />
                </Grid>

                <form className={classes.form} id="Sign_in" onSubmit={handleSubmit}>
                  <Grid container className={classes.formControlSignIn}>
                    <FormControl fullWidth error={isError} margin="normal">
                      <InputLabel className={classes.inputLabel} htmlFor="email">
                        <Typography variant="body2">Email</Typography>
                      </InputLabel>
                      <InputBase
                        autoFocus
                        required
                        autoComplete="email-c"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputField,
                        }}
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          clearError();
                          setEmail(e.target.value);
                        }}
                      />
                      <FormHelperText error={isError}>{emailPasswordError}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth error={isError} margin="normal">
                      <InputLabel className={classes.inputLabel} htmlFor="password">
                        <Typography variant="body2">Password</Typography>
                      </InputLabel>
                      <InputBase
                        required
                        autoComplete="password-c"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputField,
                        }}
                        id="password"
                        inputProps={{
                          maxLength: '30',
                          minLength: '5',
                          autoComplete: 'true',
                        }}
                        name="password"
                        placeholder="Enter Password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          clearError();
                          setPassword(e.target.value);
                        }}
                      />
                      <FormHelperText error={isError}>{emailPasswordError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid container alignItems="flex-start" className={classes.formButton} justifyContent="center">
                    <Button fullWidth className="normal" disabled={isDisabled} size="large" type="submit">
                      Sign In
                    </Button>
                    <Grid item className={classes.link}>
                      <Link
                        onClick={() => {
                          navigate('/forget');
                        }}
                      >
                        Reset password
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.grid}>
                    <Typography className={classes.subTitle} variant="inherit">
                      Not registered yet?
                    </Typography>
                    <Grid item>
                      <Link
                        onClick={() => {
                          navigate('/sign-up');
                        }}
                      >
                        Sign up!
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </div>
          )}
        </Fragment>
        <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
      </AccountLoader>
    </main>
  );
}

SignInPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SignInPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(SignInPageView, styles));
