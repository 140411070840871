import { withStyles } from 'tss-react/mui';
import React from 'react';
import Grid from '@mui/material/Grid';

const styles = theme => ({
  root: {
    marginBottom: '20px',
  },
});

const RowView = ({ children, classes }) => (
  <Grid container className={classes.root} wrap="nowrap">
    {children}
  </Grid>
);

export const Row = withStyles(RowView, styles);
