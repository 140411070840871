import { withStyles } from 'tss-react/mui';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Fragment, useEffect, useMemo } from 'react';
import { Collapse, Grid, Link, ListItemText, ListItemIcon, ListItem, List, Drawer, Divider } from '@mui/material';

import { NotificationsAlerts } from 'features/notification/components';
import { getKYCStatus } from 'features/kyc/status';
import { KYCResults } from 'features/kyc/models';
import { ReactComponent as WalletIcon } from 'assets/icons/Wallet.svg';
import { ReactComponent as TransactionsIcon } from 'assets/icons/Transactions.svg';
import { ReactComponent as TradingIcon } from 'assets/icons/Trading Account.svg';
import { ReactComponent as StakingIcon } from 'assets/icons/Staking.svg';
import { ReactComponent as StablecoinsIcon } from 'assets/icons/Stablecoin.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/Profile.svg';
import { ReactComponent as OfferingsIcon } from 'assets/icons/Offerings.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo-white.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/Log Out.svg';

import { actions as commonActions } from '../symbiotes';
import { DGRPositionProductsSelector, FRTPositionProductsSelector, getNavigationSelector, getOpenTreesSelector, structuredDepositPositionProductsSelector } from '../selectors';
import { NavigationPositions } from '../models';
import { useWidth } from '../hooks';
import { getClientPositionProductsOnly, getPublicPositionProductsOnly } from '../effects';
import { WIDTH_PANEL, VNX_SITE, INTERCOM_APP_ID } from '../constants';
import { logout } from '../../auth/effects/logout';
import { clientSelector, kycReportSelector } from '../../account/selectors';

import { LoginIcon } from './avatar';

const styles = {
  root: {
    background: '#F4F5F6',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    display: 'block',
  },
  notification: {
    transform: 'rotate(45deg)',
    cursor: 'pointer',
  },
  drawerPaper: {
    width: WIDTH_PANEL,
    background: '#F4F5F6',
    border: 'none',
    padding: '24px ',
  },
  sideBarList: {
    padding: 0,
    alignSelf: 'center',
    color: '#9FA4AE',
  },
  sideMenuTop: {
    paddingBottom: '48px',
  },
  treeList: {
    '& > .MuiListItem-root': {
      paddingLeft: '36px',
    },
  },
  sideBarItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: '1',
    webkitBoxOrient: 'vertical',
  },
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout),
  setNav: index => dispatch(commonActions.setNav(index)),
  setOpenTrees: data => dispatch(commonActions.setOpenTrees(data)),
  getClientPositionProductsOnly: () => dispatch(getClientPositionProductsOnly),
  getPublicPositionProductsOnly: () => dispatch(getPublicPositionProductsOnly),
});

const mapStateToProps = state => ({
  KYCReport: kycReportSelector(state),
  accountClient: clientSelector(state),
  navigationPosition: getNavigationSelector(state),
  openedTrees: getOpenTreesSelector(state),
  DGRPositionProducts: DGRPositionProductsSelector(state),
  FRTPositionProducts: FRTPositionProductsSelector(state),
  structuredDepositPositionProducts: structuredDepositPositionProductsSelector(state),
});

function SideMenuView({
  classes,
  handleDrawerToggle,
  mobileOpen,
  setMobileOpen,
  logout,
  KYCReport,
  accountClient,
  setNav,
  navigationPosition,
  openedTrees,
  setOpenTrees,
  getClientPositionProductsOnly,
  getPublicPositionProductsOnly,
  DGRPositionProducts,
  FRTPositionProducts,
  structuredDepositPositionProducts,
}) {
  const navigate = useNavigate();
  const KYCStatus = getKYCStatus(KYCReport);
  const width = useWidth();
  const { shutdown, boot  } = useIntercom();
  useEffect(() => {
    if (KYCStatus === KYCResults.PASSED) {
      getClientPositionProductsOnly();
    } else {
      getPublicPositionProductsOnly();
    }
  }, [ KYCStatus, getClientPositionProductsOnly, getPublicPositionProductsOnly ]);

  const menuItems = useMemo(() => {
    let menu = [
      [
        {
          index: NavigationPositions.DASHBOARD,
          name: 'Dashboard',
          component: <TradingIcon height={20} width={20} />,
          path: '/account',
        },
        {
          index: NavigationPositions.WALLETS,
          name: 'Wallet',
          component: <WalletIcon height={20} width={20} />,
          path: '/account/wallet',
        },
        {
          index: NavigationPositions.TRANSACTIONS,
          name: 'Transactions',
          component: <TransactionsIcon height={20} width={20} />,
          path: '/account/transactions',
        },
      ],
      [
        {
          index: 'metals',
          name: 'Precious Metals',
          component: <OfferingsIcon height={20} width={20} />,
          children: DGRPositionProducts
            .map(elem => ({
              index: NavigationPositions.OFFERINGS + `/directsales/${elem.id}`,
              name: elem.ticker,
              component: null,
              path: `/directsales/${elem.id}/offering`,
            })),
        },
      ],
      [
        {
          index: 'account',
          name: 'My Account',
          component: <ProfileIcon height={20} width={20} />,
          children: [
            {
              index: NavigationPositions.INFORMATION,
              name: 'My Profile',
              component: null,
              path: '/account/information',
            },
            {
              index: NavigationPositions.ADDRESSES,
              name: 'Withdrawal Addresses',
              component: null,
              path: '/account/addresses',
            },
            {
              index: NavigationPositions.API_KEYS,
              name: 'API Keys',
              component: null,
              path: '/account/keys',
            },
            {
              index: NavigationPositions.NOTIFICATIONS,
              name: 'Notification Center',
              component: null,
              path: '/account/notifications',
            },
          ],
        },
      ],
      [
        {
          index: NavigationPositions.LOGOUT,
          name: 'Log Out',
          component: <LogoutIcon height={20} width={20} />,
          path: '/logout',
        },
      ],
    ];

    if (structuredDepositPositionProducts?.length > 0) {
      menu.splice(2, 0, [
        {
          index: 'staking',
          name: 'Staking',
          component: <StakingIcon height={20} width={20} />,
          children: structuredDepositPositionProducts.map(elem => ({
            index: NavigationPositions.STAKING + `/${elem.id}`,
            name: elem.name,
            component: null,
            path: `/staking/${elem.id}/info`,
          })),
        },
      ]);
    }

    if (FRTPositionProducts.length > 0) {
      menu.splice(2, 0, [
        {
          index: 'stablecoins',
          name: 'Fiat Referenced Tokens',
          component: <StablecoinsIcon height={20} width={20} />,
          children: FRTPositionProducts.map(elem => ({
            index: NavigationPositions.OFFERINGS + `/directsales/${elem.id}`,
            name: elem.ticker,
            component: null,
            path: `/directsales/${elem.id}/offering`,
          })),
        },
      ]);
    }

    return menu;
  }, [ DGRPositionProducts, structuredDepositPositionProducts, FRTPositionProducts ]);

  const menuItemClicked = async (item) => {
    setNav(item.index);
    setMobileOpen(false);
    if (item.path === '/logout') {
      shutdown();
      await logout();
      navigate('/join');
      boot({ appId: INTERCOM_APP_ID });
    } else {
      navigate(item.path);
    }
  };

  const toggleTreeItem = (item) => {
    setOpenTrees({ [item]: !openedTrees[item] });
  };

  const getItem = (item) => {
    if (!!item.children) {
      return (
        <Fragment key={item.index.toString() + 'c'}>
          <ListItem
            button
            onClick={() => {
              toggleTreeItem(item.index);
            }}
          >
            <ListItemIcon>{item.component}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
          <Collapse in={openedTrees[item.index]}>
            <List className={`${classes.sideBarList} ${classes.treeList}`} component="nav">
              {item.children.map(getItem)}
            </List>
          </Collapse>
        </Fragment>
      );
    }

    return (
      <ListItem
        button
        key={item.index.toString() + 'p'}
        selected={navigationPosition === item.index}
        onClick={() => {
          menuItemClicked(item);
        }}
      >
        <ListItemIcon>{item.component}</ListItemIcon>
        <ListItemText className={classes.sideBarItemText} primary={item.name} />
      </ListItem>
    );
  };

  const sideMenuItemsList = (
    <Grid container direction="column">
      {menuItems.map((menuItem, index) => (
        <Fragment key={index.toString() + 'side'}>
          {index !== 0 && <Divider key={index.toString() + 'd'} />}
          <List className={classes.sideBarList} component="nav" key={index.toString() + 'l'}>
            {menuItem.map(getItem)}
          </List>
        </Fragment>
      ))}
    </Grid>
  );

  const sideMenuTop = (
    <Grid container alignItems="center" className={classes.sideMenuTop} justifyContent="space-between" wrap="nowrap">
      <Link href={VNX_SITE} rel="noreferrer noopener" target="_blank">
        <LogoIcon className={classes.logo} height={20} />
      </Link>
      <Grid container item alignItems="center" justifyContent="flex-end" wrap="nowrap">
        <LoginIcon client={accountClient} />
        <NotificationsAlerts />
      </Grid>
    </Grid>
  );

  return (
    <nav aria-label="mailbox folders" className={classes.drawer} key="wrapperNav">
      {[ 'xs', 'sm', 'ssm' ].includes(width) ? (
        <Drawer
          ModalProps={{
            keepMounted: true,
          }}
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
          key="small"
          open={mobileOpen}
          variant="temporary"
          onClose={handleDrawerToggle}
        >
          {sideMenuTop}
          {sideMenuItemsList}
        </Drawer>
      ) : (
        <Drawer
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          key="big"
          variant="permanent"
        >
          {sideMenuTop}
          {sideMenuItemsList}
        </Drawer>
      )}
    </nav>
  );
}

export const SideNav = connect(mapStateToProps, mapDispatchToProps)(withStyles(SideMenuView, styles));
