import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useMemo } from 'react';

import { countNewNotificationsSelector } from '../selectors';
import { getNotifications } from '../effects';

import { RingIcon } from './ring-icon';

const mapDispatchToProps = dispatch => ({
  getNotifications: query => dispatch(getNotifications, query),
});

const mapStateToProps = state => ({
  countNewItems: countNewNotificationsSelector(state),
});
export const NotificationsAlertsView = ({ getNotifications, countNewItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, [ getNotifications ]);

  const isMatch = useMemo(() => (location.pathname === '/account/notifications' ? true : false), [ location ]);

  return (
    <div>
      <RingIcon
        hasNotifications={Boolean(countNewItems)}
        isActive={isMatch}
        onClick={() => {
          navigate('/account/notifications');
        }}
      />
    </div>
  );
};

export const NotificationsAlerts = connect(mapStateToProps, mapDispatchToProps)(NotificationsAlertsView);
