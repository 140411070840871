import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';

export const useNotificationService = ({ message, updateNotifications }) => {
  const { enqueueSnackbar } = useSnackbar();
  const notify = useCallback(
    (item) => {
      enqueueSnackbar(item, {
        key: item.id,
      });
      updateNotifications(item);
    },
    [ updateNotifications, enqueueSnackbar ]
  );

  useEffect(() => {
    if (message?.id) {
      notify(message);
    }
  }, [ message, notify ]);
};
