import { actions } from '../symbiotes';
import { sessionApi } from '../api';
import { getResponse } from '../../common/response';
import { ResponseStatus } from '../../common/constants';

import { checkAuthenticated } from './check-authenticated';

export const join =
  ({ login, password, code }) =>
    async (dispatch) => {
      try {
        const response = await dispatch(sessionApi.create, {
          login,
          password,
        });

        if (response && !response.code) {
          localStorage.setItem('refreshToken', JSON.stringify(response.refresh_token));
          localStorage.setItem('accessToken', JSON.stringify(response.access_token));
          await dispatch(actions.auth.reset());
          const data = await dispatch(checkAuthenticated);
          if (data && data.status === ResponseStatus.FORBIDDEN) {
            return getResponse(data);
          }
          return getResponse(response);
        }
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error), type: 'unknown_error' } };
      }
    };
