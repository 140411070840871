import { withStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { Input, Grid } from '@mui/material';
import { cx } from '@emotion/css';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

const styles = theme => ({
  root: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.01em',
    border: '1px solid #E4E6E8',
    borderRadius: 8,
    padding: '10px 15px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: '66px',
    justifyContent: 'space-between',
  },
  input: {
    color: '#000',
  },
  icon: {
    height: 25,
    width: 25,
  },
});
const USER_INPUT_TIME = 800;
const ENTER_BTN = 13;

const SearchView = ({ children, classes, className, onSearch }) => {
  const [ searchWord, setSearchWord ] = useState('');
  const [ timer, setTimer ] = useState(null);

  const handleChange = (value) => {
    clearTimeout(timer);

    setSearchWord(value);

    setTimer(setTimeout(() => onSearch({ Search: value }), USER_INPUT_TIME));
  };

  const handleEnter = (e) => {
    if (e.keyCode === ENTER_BTN) {
      onSearch({ Search: e.target.value });
      clearTimeout(timer);
    }
  };

  return (
    <Grid container item className={cx(classes.root, className)}>
      <Input
        disableUnderline
        fullWidth
        className={classes.input}
        placeholder="Search.."
        type="search"
        value={searchWord}
        onChange={e => handleChange(e.target.value)}
        onKeyDown={handleEnter}
      />
      <SearchIcon className={classes.icon} onClick={() => onSearch(searchWord)} />
    </Grid>
  );
};

export const Search = withStyles(SearchView, styles);
