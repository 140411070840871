import { withStyles } from 'tss-react/mui';
import React from 'react';
import { cx } from '@emotion/css';

const styles = theme => ({
  root: {
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
    background: 'rgba(255,255,255, 0.2)',
    borderRadius: '4px',
    height: '28px',
    '&:not(:last-child)': {
      marginRight: '19px',
    },
    '&.hot': {
      color: '#fff',
    },
    '&.hotAccount': {
      background: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      color: '#fff',
    },
    '&.nonHot': {
      border: '1px solid rgba(0, 0, 0, 0.16)',
      color: '#000',
    },
  },
});

const TagView = ({ children, classes, type = 'nonHot' }) => <div className={cx(classes.root, type)}>{children}</div>;

export const Tag = withStyles(TagView, styles);
