export const KYCResults = {
  IDLE: 'idle',
  NEW: 'new',
  PASSED: 'passed',
  REJECTED: 'rejected',
  RETRY: 'retry',
  EXPIRESOON: 'soon',
};

export const KYCStatuses = {
  NONE: 'none',
  INIT: 'init',
  PENDING: 'pending',
  QUEUED: 'queued',
  COMPLETED: 'completed',
  ON_HOLD: 'onHold',
};

export const KYCPageStatusProgress = {
  NEW: 'new',
  TYPE: 'type',
  COMPLETE: 'complete',
};

export const KYCTypeClientModels = {
  individual: 'individual',
  legalEntity: 'legalEntity',
};

export const KYCClientTypes = {
  INDIVIDUAL: 'individual',
  LEGAL_ENTITY: 'legalEntity',
};
