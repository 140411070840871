import { withStyles } from 'tss-react/mui';
import DatePicker from 'react-datepicker';
import React from 'react';
import { getTimezoneOffset } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { Grid, Typography } from '@mui/material';

import 'react-datepicker/dist/react-datepicker.css';
import 'ui/datepicker/datepicker.css';
import cross from 'assets/cross.svg';

const styles = theme => ({
  root: {
    width: 'auto',
  },
  datepicker: {
    width: '100%',
  },
  label: {
    color: '#9FA4AE',
    marginRight: '10px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
  },
  error: {
    color: 'red',
    opacity: '0.6',
    fontWeight: 300,
    fontSize: '14px',
    marginBottom: '10px',
  },
  delete: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
});

const DatepickerView = ({ value, onChange, onDelete, classes, label, disabled, error, helperText, className, type, ...props }) => {
  const timeZoneBerlin = getTimezoneOffset('Europe/Berlin', new Date().getTime());
  const localTimeZone = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone.toString());

  return (
    <Grid container alignItems="center" className={classes.root} direction="row" wrap="nowrap">
      {label && (
        <Grid item>
          <Typography className={error ? classes.error : classes.label} variant="body2">
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.datepicker}>
        <label>
          <DatePicker
            className={error ? 'error' : 'datepicker'}
            dateFormat={`dd.MM.yyyy ${type === 'datetime' ? 'HH:mm' : ''}`}
            popperPlacement="bottom-start"
            readOnly={disabled}
            selected={value}
            showTimeSelect={type === 'datetime'}
            timeFormat="HH:mm"
            timeIntervals={1}
            onCalendarOpen={() => {
              if (Boolean(type === 'datetime')) {
                if (value && isValid(value)) {
                } else {
                  const newdate = new Date(new Date().getTime() - localTimeZone + timeZoneBerlin);
                  onChange(newdate);
                }
              }
            }}
            onChange={onChange}
            {...props}
          />
        </label>
      </Grid>
      {onDelete && (
        <Grid item className={classes.delete}>
          <img alt="Delete" className={classes.deleteIcon} src={cross} onClick={onDelete} />
        </Grid>
      )}
    </Grid>
  );
};

export const Datepicker = withStyles(DatepickerView, styles);
