import { Navigate } from 'react-router-dom';
import React from 'react';

import { OfferingsType, TabsOffering } from './models';

const OfferingPage = React.lazy(() => import('./pages/offering-page'));
const OfferingsPage = React.lazy(() => import('./pages/offerings-page'));
const OfferingInfoTab = React.lazy(() => import('./components/offering-info/offering-info-tab'));
const BiddingInfoTab = React.lazy(() => import('./components/bidding-info/bidding-info-tab'));
const GoldBarsTab = React.lazy(() => import('./components/gold-bars/gold-bars-tab'));
const NotFoundPage = React.lazy(() => import('features/common'));

export const offeringRoutes = [
  {
    path: `/${OfferingsType.DIRECTSALES}`,
    element: <Navigate to={`/${OfferingsType.OFFERINGS}`} />,
  },
  {
    path: `/${OfferingsType.OFFERINGS}`,
    element: <OfferingsPage />,
  },
  {
    path: `/${OfferingsType.DIRECTSALES}/:id`,
    element: <OfferingPage type={OfferingsType.DIRECTSALES} />,
    children: [
      {
        path: '',
        element: <OfferingInfoTab />,
      },
      {
        path: `${TabsOffering.offeringInfo.route}`,
        element: <OfferingInfoTab />,
      },
      {
        path: `${TabsOffering.biddingInfo.route}`,
        element: <BiddingInfoTab />,
      },
      {
        path: `${TabsOffering.goldBars.route}`,
        element: <GoldBarsTab />,
      },
    ],
  },
  {
    path: `/${OfferingsType.OFFERINGS}/:id`,
    element: <OfferingPage type={OfferingsType.OFFERINGS} />,
    children: [
      {
        path: '',
        element: <OfferingInfoTab />,
      },
      {
        path: `${TabsOffering.offeringInfo.route}`,
        element: <OfferingInfoTab />,
      },
      {
        path: `${TabsOffering.biddingInfo.route}`,
        element: <BiddingInfoTab />,
      },
    ],
  },
  {
    path: '/*',
    element: <NotFoundPage />,
  },
];
