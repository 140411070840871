import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Button, Grid, Link, FormHelperText, FormControl } from '@mui/material';

import { trimFilePath } from 'lib/string';
import { FILES_HOST } from 'features/common/constants';

import { uploadFile, deleteFile } from '../effects';

const mapDispatchToProps = dispatch => ({
  uploadFile: query => dispatch(uploadFile, query),
  deleteFile: name => dispatch(deleteFile, name),
});

const ImageView = ({ name, readOnly, helperText, onDelete, handleUpload, uploadFile, deleteFile }) => {
  const [ error, setError ] = useState(null);
  const [ inputValue, setInputValue ] = useState('');

  const maxSize = 1024;

  const onFileLoad = async (e) => {
    const fileName = e.target.files[0]?.name;
    const fileSize = e.target.files[0]?.size / 1024 / 1024; // in MB
    const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

    if (fileSize > maxSize) {
      setError(`The maximum size is ${maxSize} MB`);
      setInputValue('');
    } else if (![ 'png', 'jpg', 'jpeg' ].includes(extension)) {
      setError('Only PNG, JPG, JPEG images files are accepted');
      setInputValue('');
    } else {
      setError(null);
      const data = new FormData();
      data.append('data', e.target.files[0]);
      const { ok, data: file, error } = await uploadFile(data);
      if (ok) {
        handleUpload(file.name);
      } else {
        alert(error);
      }
    }
  };

  const onFileDelete = async () => {
    const { ok, error } = await deleteFile(name);
    if (ok) {
      handleUpload('');
    } else {
      setError(Object.keys(error?.data).length === 0 ? '' : error.data);
    }
    onDelete && onDelete({ ok, error });
  };

  return (
    <FormControl error={!!error || !!helperText}>
      <input alt="Logo" disabled={readOnly} id="contained-button-file" style={{ display: 'none' }} type="file" value={inputValue} onChange={onFileLoad} />

      {onDelete && name ? (
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid item>
            {name && (
              <Link href={`${FILES_HOST}${name}`} target="_blank">
                {`${trimFilePath(name)}`}
              </Link>
            )}
          </Grid>
          <Grid item>
            <label>
              <Button className="normal" component="span" onClick={onFileDelete}>
                Delete file
              </Button>
            </label>
          </Grid>
        </Grid>
      ) : (
        <label htmlFor="contained-button-file">
          <Button className="normal" component="span">
            Select file
          </Button>
        </label>
      )}
      <FormHelperText>{error || helperText}</FormHelperText>
    </FormControl>
  );
};

export const Image = connect(null, mapDispatchToProps)(ImageView);
