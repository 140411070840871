import { handleFetching } from 'symbiote-fetching';

import { mapAllTerms } from 'features/common/mappers';

import { actions } from '../common/symbiotes';
import { getResponse } from '../common/response';

import { termsApi } from './api';

export const saveTerms = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(termsApi.saveTerms, query);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAllTerms = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(termsApi.getTerms);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(actions.setAllTerms(mapAllTerms(response.source_data)));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
