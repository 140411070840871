import { handleFetching } from 'symbiote-fetching';

import { getResponse } from 'features/common/response';

import { actions as actionsNotifications } from '../notification/symbiotes';

import { actions } from './symbiotes';
import { mapSortTransactions } from './mappers';
import { transactionsApi } from './api';

export const getTransactions = query =>
  handleFetching(actions.fetchTransactions, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setTransactions([]));
        const response = await dispatch(transactionsApi.getTransactions, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setTransactions(mapSortTransactions(response.rows)));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicTransactions = query =>
  handleFetching(actions.fetchTransactions, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setTransactions([]));
        const response = await dispatch(transactionsApi.getPublicTransactions, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setTransactions(mapSortTransactions(response.rows)));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const updateGetTransactions = query =>
  handleFetching(actions.fetchUpdateTransactions, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setTransactions([]));
        const response = await dispatch(transactionsApi.getTransactions, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setTransactions(mapSortTransactions(response.rows)));
          await dispatch(actionsNotifications.setEventPush({ eventSended: false }));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const updatePublicGetTransactions = () =>
  handleFetching(actions.fetchUpdateTransactions, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setTransactions([]));
        const response = await dispatch(transactionsApi.getPublicTransactions, {});
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setTransactions(mapSortTransactions(response.rows)));
          await dispatch(actionsNotifications.setEventPush({ eventSended: false }));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const refund = (data, noKyc = false) =>
  handleFetching(actions.fetchRefund, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(noKyc ? transactionsApi.refundNoKyc : transactionsApi.refund, data);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkRefundPosition = (query, noKyc) =>
  handleFetching(actions.fetchCheckRefundPosition, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setRefundPosition({}));
        const response = await dispatch(noKyc ? transactionsApi.checkRefundPositionsNoKyc : transactionsApi.checkRefundPositions, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setRefundPosition(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
