import { withStyles } from 'tss-react/mui';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { cx } from '@emotion/css';

const styles = theme => ({
  root: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.01em',
  },
  value: {
    wordBreak: 'break-word',
  },
});

const ValueView = ({ children, classes, className }) => (
  <Grid container item className={cx(classes.root, className)}>
    <Typography className={classes.value} variant="inherit">
      {children}
    </Typography>
  </Grid>
);

export const Value = withStyles(ValueView, styles);
