import { withStyles } from 'tss-react/mui';
import { Document, Page  } from 'react-pdf';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Link, Grid, FormControlLabel } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { Hyperlink } from 'ui/hyperLink';
import { Spinner } from 'ui';
import { termsDocType, termsPath, TermTypes } from 'features/terms/models';
import { useWindowSize } from 'features/common/hooks';
import { ReactComponent as Full } from 'assets/full.svg';
import { ReactComponent as ExitFull } from 'assets/exitFull.svg';

import { Checkbox } from './checkbox';

const icons = {
  Full: <Full />,
  Exit: <ExitFull />,
};

const styles = theme => ({
  form: {},
  title: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
  },
  confirm: {
    color: '#FFF',
    marginTop: '20px',
    '& span span> a': {
      textDecoration: 'none',
      color: '#00BDFF',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        color: '#00A2DB',
      },
      '&:active': {
        textDecoration: 'underline',
        color: '#008ABB',
      },
    },
  },
  footer: {
    marginTop: '30px',
    paddingLeft: '70px',
    paddingRight: '70px',
  },
  wrapperTerms: {
    display: 'flex',
    border: '1px solid #E3E3E3',
    flexDirection: 'column',
    height: '40vh',
    backgroundColor: '#25272B',
    maxWidth: '1160px',
    overflowY: 'scroll',
    alignItems: 'center',
    '@media screen and (max-device-width: 920px)': {
      width: 'calc(80vw + 38px)',
    },
  },
  helpLink: {
    paddingRight: '15px',
    paddingBottom: '10px',
    textDecoration: 'none',
    color: '#BABEC5',
    fontSize: '14px;',
  },
  divider: {
    margin: '5px 0',
  },
  icon: {
    position: 'absolute',
    padding: '5px',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: '999',
    height: '100%',
    maxWidth: 'none',
  },
});

const Iubenda = ({ classes, policyId }) => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <Link
      className={`iubenda-nostyle no-brand iubenda-noiframe iubenda-embed ${classes.helpLink}`}
      href={`https://www.iubenda.com/privacy-policy/${policyId}`}
      title="Privacy Policy "
      //class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe  "
    >
      <OpenInNew style={{ verticalAlign: 'bottom' }} />
    </Link>
  );
};

const TermsFormView = ({ classes, terms, handleSubmit }) => {
  const { GENERAL } = termsDocType;
  const [ confirmTerms, setConfirmTerms ] = useState(false);
  const [ confirmPrivacy, setConfirmPrivacy ] = useState(false);
  const [ confirmPersonalData, setConfirmPersonalData ] = useState(false);
  const [ numPages, setNumPages ] = useState(null);
  const [ fullScreen, setFullScreen ] = useState(false);
  const [ pageWidth, setPageWidth ] = useState(1060);

  const pagesCount = useMemo(() => {
    let pages = [];
    for (let i = 0; i < numPages; i++) {
      pages[i] = i + 1;
    }
    return pages;
  }, [ numPages ]);

  const { width } = useWindowSize(300);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    if (width < 1060) {
      setPageWidth(width - 20);
    }
  }

  const isSmallDisplay = useMemo(() => width < 600, [ width ]);
  const availWidth = useMemo(() => width * 0.9, [ width ]);

  const fullScreenProps = useMemo(() => {
    if (!fullScreen)
      return {
        wrapper: classes.wrapperTerms,
        icon: icons.Full,
      };
    if (fullScreen)
      return {
        wrapper: `${classes.wrapperTerms} ${classes.fullScreen}`,
        icon: icons.Exit,
      };
  }, [ fullScreen, classes ]);

  const file =  useMemo(
    () => ({ url: termsPath[GENERAL] }),
    [ GENERAL ]
  );

  return (
    <Fragment>
      {!isSmallDisplay ?
        <div className={fullScreenProps.wrapper}>
          <div className={classes.icon} onClick={() => setFullScreen(prev => !prev)}>
            {fullScreenProps.icon}
          </div>
          <Document file={file} loading={<Spinner />} renderMode='svg' onLoadError={e => console.error(e, 'error')} onLoadSuccess={onDocumentLoadSuccess}>
            {pagesCount.map(el => (
              <Fragment key={`page_${el}`}>
                <Page pageNumber={el} renderAnnotationLayer={false} width={!fullScreen ? pageWidth : availWidth} />
                <Divider className={classes.divider} variant="fullWidth" />
              </Fragment>
            ))}
          </Document>
        </div> : null}
      <Grid container direction="column">
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmTerms}
              onChange={(e) => {
                setConfirmTerms(e.target.checked);
              }}
            />
          }
          label={<Hyperlink overflow={false} preText={terms[TermTypes.GENERAL_TERMS]?.description + ' '} text={<OpenInNew style={{ verticalAlign: 'bottom' }} />} url={termsPath[GENERAL]} />}
        />
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmPrivacy}
              onChange={(e) => {
                setConfirmPrivacy(e.target.checked);
              }}
            />
          }
          label={
            <span>
              {terms[TermTypes.PRIVACY_TERMS]?.description} <Iubenda classes={classes} policyId="28209609" />
            </span>
          }
        />
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmPersonalData}
              onChange={(e) => {
                setConfirmPersonalData(e.target.checked);
              }}
            />
          }
          label={terms[TermTypes.PERSONAL_DATA_TERMS]?.description}
        />
      </Grid>
      <Grid container alignItems="center" className={classes.footer} direction="column" justifyContent="center">
        <Button className="normal" disabled={!(confirmTerms && confirmPrivacy && confirmPersonalData)} size="large" type="submit" onClick={handleSubmit}>
          Create account
        </Button>
      </Grid>
    </Fragment>
  );
};

TermsFormView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const TermsForm = withStyles(TermsFormView, styles);
