import { withStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';
import React from 'react';

export const SpinnerType = {
  DEFAULT: 'default',
  BUTTON: 'button',
};

const ldsEllipsis1 = keyframes`
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const styles = (theme, { type = SpinnerType.DEFAULT }) => {
  const lds2translate = type === SpinnerType.BUTTON ? '12px' : '24px';
  const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${lds2translate}, 0);
  }
`;
  return {
    root: {
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      width: 'auto',
      minHeight: '100%',
      alignItems: 'center',
    },
    colorSpinner: {
      background: '#00BDFF',
    },
    ldsEllipsis: {
      display: 'inline-block',
      position: 'relative',
      width: type === SpinnerType.BUTTON ? '40px' : '80px',
      height: type === SpinnerType.BUTTON ? '40px' : '80px',
      '& div': {
        position: 'absolute',
        top: type === SpinnerType.BUTTON ? '16.5px' : '33px',
        width: type === SpinnerType.BUTTON ? '7px' : '13px',
        height: type === SpinnerType.BUTTON ? '7px' : '13px',
        borderRadius: '50%',
        animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
      },
      '& div:nth-of-type(1)': {
        left: type === SpinnerType.BUTTON ? '4px' : '8px',
        animation: `${ldsEllipsis1} 0.6s infinite`,
      },
      '& div:nth-of-type(2)': {
        left: type === SpinnerType.BUTTON ? '4px' : '8px',
        animation: `${ldsEllipsis2} 0.6s infinite`,
      },
      '& div:nth-of-type(3)': {
        left: type === SpinnerType.BUTTON ? '16px' : '32px',
        animation: `${ldsEllipsis2} 0.6s infinite`,
      },
      '& div:nth-of-type(4)': {
        left: type === SpinnerType.BUTTON ? '28px' : '56px',
        animation: `${ldsEllipsis3} 0.6s infinite`,
      },
    },
  };
};

const SpinnerView = ({ classes, type }) => (
  <div className={classes.root}>
    <div className={classes.ldsEllipsis}>
      <div className={classes.colorSpinner} />
      <div className={classes.colorSpinner} />
      <div className={classes.colorSpinner} />
      <div className={classes.colorSpinner} />
    </div>
  </div>
);

export const Spinner = withStyles(SpinnerView, styles);
