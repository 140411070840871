import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Button as ButtonMaterials } from '@mui/material';
import { cx } from '@emotion/css';

const styles = theme => ({
  root: {
    position: 'relative',
    width: 'auto',
    height: '40px',
    border: 'none',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.05em',
    lineHeight: '17px',
    textTransform: 'uppercase',
    borderRadius: '0px',
    boxShadow: 'inherit',
    padding: '0 20px 0 20px',
    '&:hover': {
      border: 'none',
      boxShadow: 'inherit',
    },
    '&:disabled': {
      border: 'none',
      boxShadow: 'inherit',
    },
  },
  normal: {
    color: '#FFF',
    backgroundColor: '#00BDFF',
    '&:hover': {
      backgroundColor: '#333333',
      color: '#FFFFFF',
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#DADADA',
    },
  },
  white: {
    color: '#00BDFF',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CCF2FF',
      color: '#00BDFF',
    },
    '&:disabled': {
      color: '#DADADA',
      backgroundColor: '#fff',
    },
  },
  auction: {
    color: '#FFF',
    backgroundColor: '#ED2939',
    '&:hover': {
      backgroundColor: '#FBD4D7',
      color: '#ED2939',
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#DADADA',
    },
  },
});

/**
 *
 * @param {string} theme - white, auction, normal(default)
 */

const ButtonView = ({ children, classes, className, theme = 'normal', type = 'button', variant, disabled, onClick }) => (
  <ButtonMaterials className={cx(classes.root, classes[theme], className)} disabled={disabled} type={type} variant={variant} onClick={onClick}>
    {children || ''}
  </ButtonMaterials>
);

export const Button = withStyles(ButtonView, styles);
