import { handleFetching } from 'symbiote-fetching';

import { getResponse } from '../common/response';

import { actions } from './symbiotes';
import { mapNotifications } from './mappers';
import { notificationsApi } from './api';

export const getNotifications = query =>
  handleFetching(actions.fetchGetNotifications, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(notificationsApi.getNotifications, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setNotifications(mapNotifications(data.source_data)));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const markReadNotification = id =>
  handleFetching(actions.fetchMarkReadNotification, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(notificationsApi.markRead, id);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(getNotifications);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const markReadNotificationAll = () =>
  handleFetching(actions.fetchMarkReadNotificationAll, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(notificationsApi.markReadAll);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const getSettings = () =>
  handleFetching(actions.fetchGetSettings, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(notificationsApi.getSettings);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setSettings(data.source_data));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const setSettings = newData =>
  handleFetching(actions.fetchSetSettings, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(notificationsApi.setSettings, newData);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const addNotification = data =>
  handleFetching(actions.fetchAddNotification, {
    noThrow: false,
    async run(dispatch) {
      try {
        await dispatch(actions.addNotification(data));
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const addAlertNotification = data =>
  handleFetching(actions.fetchAddAlertNotification, {
    noThrow: false,
    async run(dispatch) {
      try {
        await dispatch(actions.addAlertNotification(data));
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const addEventPush = data =>
  handleFetching(actions.fetchAddEventPush, {
    noThrow: false,
    async run(dispatch) {
      try {
        await dispatch(actions.setEventPush(data));
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

