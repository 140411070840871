import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const prepareValue = (displayData, sortData, id = null) => {
  const data = {  displayData: displayData, sortData: sortData };
  if (id) data.id = id;
  return data;
};

export const filterByValue = (array, value) => array.filter(data => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [ search ]);
};

export const getUserBrowser = () => {
  let browser = '';
  const { userAgent } = navigator;
  if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Mozilla Firefox';
  } else if (userAgent.indexOf('OPR') > -1) {
    browser = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browser = 'Microsoft Internet Explorer';
  } else if (userAgent.indexOf('Edg') > -1) {
    browser = 'Microsoft Edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Google Chrome or Chromium';
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Apple Safari';
  } else {
    browser = 'unknown browser';
  }
  return browser;
};
