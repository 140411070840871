import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchAuth: initialFetching,
  fetchSignOut: initialFetching,
  fetchConfirmEmail: initialFetching,
  fetchToken: initialFetching,
  authenticated: false,
  eventIdleUpdate: {},
};

const symbiotes = {
  fetchAuth: createFetching('fetchAuth'),
  fetchSignOut: createFetching('fetchSignOut'),
  fetchConfirmEmail: createFetching('fetchConfirmEmail'),
  fetchToken: createFetching('fetchToken'),
  auth: {
    set: (state, data) => ({ ...state, ...data }),
    reset: () => ({}),
  },
  selfRegistration: {
    set: (state, data) => ({ ...state, register: data }),
    reset: () => ({ register: {} }),
  },
  updateIdleTimeout: state => ({ ...state, eventIdleUpdate: {} }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'auth');
