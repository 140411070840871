import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchGetNotifications: initialFetching,
  fetchGetSettings: initialFetching,
  fetchSetSettings: initialFetching,
  fetchAddNotification: initialFetching,
  fetchMarkReadNotificationAll: initialFetching,
  fetchMarkReadNotification: initialFetching,
  fetchAddAlertNotification: initialFetching,
  fetchAddEventPush: initialFetching,
  notifications: [],
  alertNotifications: [],
  eventPush: { eventSended: false },
  settings: {
    yourKycStatus: false,
    walletTransactions: false,
    conversionOperations: false,
    actionsWithBids: false,
    offeringsAndBidsStatuses: false,
    disclosures: false,
    systemOperator: true,
  },
  onFiatEuroChangedEvent: {},
  onGoldQuoteChangedEvent: {},
  onCryptoRatesChangedEvent: { tickers : [] },
};

const symbiotes = {
  fetchGetNotifications: createFetching('fetchGetNotifications'),
  fetchGetSettings: createFetching('fetchGetSettings'),
  fetchSetSettings: createFetching('fetchSetSettings'),
  fetchAddNotification: createFetching('fetchAddNotification'),
  fetchAddAlertNotification: createFetching('fetchAddAlertNotification'),
  fetchMarkReadNotificationAll: createFetching('fetchMarkReadNotificationAll'),
  fetchMarkReadNotification: createFetching('fetchMarkReadNotification'),
  fetchAddEventPush: createFetching('fetchAddEventPush'),
  setNotifications: (state, data) => ({ ...state, notifications: data }),
  addNotification: (state, data) => ({ ...state, notifications: [ data, ...state.notifications ] }),
  setSettings: (state, data) => ({ ...state, settings: data }),
  addAlertNotification: (state, data) => ({ ...state, alertNotifications: [ data, ...state.alertNotifications ] }),
  setEventPush: (state, data) => ({ ...state, eventPush: data }),
  updateOnFiatEuroChangedEvent: state => ({ ...state, onFiatEuroChangedEvent: {} }),
  updateOnGoldQuoteChangedEvent: state => ({ ...state, onGoldQuoteChangedEvent: {} }),
  updateOnCryptoRatesChangedEvent: (state, data) => ({ ...state, onCryptoRatesChangedEvent: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'notification');
