import { api } from 'features/common';

export const walletApi = {
  getPositions: data => api.get('/ui/positions', data),
  getStructuredDepositPositions: query => api.get('/ui/structured-product/positions', query),
  getPublicStructuredDepositPositions: query => api.get('/ui/nokyc/structured-product/positions', query),
  getWallet: data => api.get('/ui/crypto/getWallet', data),
  getContract: data => api.get('/ui/crypto/getContract', data),
  getMinDeposit: data => api.get('/ui/crypto/getMinDeposit', data),
  getCommission: query => api.get('/ui/crypto/getCommission', query),
  withdraw: data => api.post('/ui/crypto/withdraw', data),
  withdrawCurrency: data => api.post('/ui/currency/withdraw', data),
  conversionCheckPosition: query => api.get('/ui/crypto-auto-convert/check-positions', query),
  cryptoAutoConvert: query => api.post('/ui/crypto-auto-convert', query),
  getClientDGRPosition: () => api.get('/ui/symbols/dgrPositions'),
  getPublicDGRPosition: () => api.get('/ui/nokyc/symbols/dgrPositions'),
  getClientFRTPosition: () => api.get('/ui/symbols/frtPositions'),
  getPublicFRTPosition: () => api.get('/ui/nokyc/symbols/frtPositions'),
  getClientPosition: () => api.get('/ui/symbols/positions'),
  getPublicPosition: () => api.get('/ui/nokyc/symbols/positions'),
  getClientBankDetails: query => api.get('/ui/bank-detail/client', query),
  getClientBankDetailId: id => api.get(`/ui/bank-detail/client/${id}`),
  getPublicBankDetails: query => api.get('/ui/nokyc/bank-detail/client', query),
  getPublicBankDetailId: id => api.get(`/ui/nokyc/bank-detail/client/${id}`),
  checkCryptoWithdrawalPosition: query => api.get('/ui/crypto/withdraw/check-positions', query),
  checkCurrencyWithdrawalPosition: query => api.get('/ui/currency/withdraw/check-positions', query),
  checkCurrencyAcquiringPositions: query => api.get('/ui/currency/acquiring/check-positions', query),
  getAcquiringCheckouts: query => api.get('/ui/currency/acquiring/checkouts', query),
  getAcquiringCheckoutsPayment: query => api.get('/ui/currency/acquiring/checkouts/payment', query),
  getCountries: query => api.get('/ui/dict/countries', query),
  getAvailCryptoTickers: () => api.get('/ui/crypto-auto-convert/avail-crypto'),
  getUIProductPositions: query => api.get('/ui/product/positions', { AggregateTokens: true, ...query }),
  getPublicProductPositions: query => api.get('/ui/nokyc/product/positions', { AggregateTokens: true, ...query }),
  getCryptoBlockchains: () => api.get('/ui/nokyc/crypto/blockchains'),
  getAvailFiatToConvert: () => api.get('/ui/crypto-auto-convert/avail-fiat'),
  getAvailAssetsToConvert: () => api.get('/ui/crypto-auto-convert/avail-assets'),
  getLastTransactionBlockchain: query => api.get('/ui/transactions/last-blockchain', query),
  getSessionForPaymentIQ: () => api.get('/ui/paymentIQ/get-session'),
};
