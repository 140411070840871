export const OfferingStatus = {
  IDLE: 'idle',
  NEW: 'new',
  OPEN: 'open',
  ONGOING: 'ongoing',
  SUSPENDED: 'suspended',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  FINISHED: 'finished',
};

export const TabsOffering = {
  offeringInfo: { name: 'Product Info', index: 0, route: 'offering' },
  biddingInfo: { name: 'Documents', index: 1, route: 'bidding' },
  goldBars: { name: 'My Gold Bars', index: 2, route: 'bars' },
};

export const OfferingsType = {
  OFFERINGS: 'offerings',
  DIRECTSALES: 'directsales',
};

export const DSOfferStatus = {
  IDLE: 'idle',
  NEW: 'new',
  OPEN: 'open',
  CLOSE: 'close',
};

export const OfferType = {
  PROP: 'prop',
  TRADE: 'trade',
};
