import React from 'react';

const KycPage = React.lazy(() => import('./pages/kyc-page'));

export const kycRoutes = [
  {
    path: '/account/kyc',
    element: <KycPage />,
  },
];
