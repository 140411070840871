import React from 'react';

const WalletPage = React.lazy(() => import('./wallet-page'));

export const walletRoutes = [
  {
    path: '/account/wallet',
    element: <WalletPage />,
  },
];
