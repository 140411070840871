import { getDateTimeFormat } from 'lib/date';

const sortByDate = list => list.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

export const mapTransactions = (items) => {
  let rows = [];
  for (let item of items) {
    let row = {
      id: item.id,
      date: getDateTimeFormat(item.Date),
      type: item.Type,
      displayType: item.DisplayType,
      quantity: item.Quantity,
      asset: item.Ticker,
      status: item.Status,
      direction: item.Direction,
      hash: item?.Hash || null,
      symbolId: item.SymbolId,
      symbolType: item.SymbolType || '-',
      directSaleOfferId: item.DsId || null,
    };
    rows.push(row);
  }
  return rows;
};

export const mapSortTransactions = (list) => {
  if (list && list.length > 0) {
    return sortByDate(mapTransactions(list));
  }
  return [];
};
