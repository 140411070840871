import { isJsonString } from 'lib/string';
import { isEmpty } from 'lib/object';

import { ErrorTypes } from './models';
import { ResponseStatus } from './constants';

const redirectToJoin = () => {
  let joinURL = `${window.location.protocol}//${window.location.host}/join`;
  const currentURL = window.location.href;
  const excludePathURL = [ '/join', '/sign-up', '/change-password', '/forget', '/register', '/confirm-email', '/sign-up/success' ];
  const isNotExcludeURL = !excludePathURL.filter(path => Boolean(currentURL.match(path))).length;

  if (isNotExcludeURL) {
    window.location.href = joinURL;
  }
};

export function getResponse(response) {
  if (response.status === ResponseStatus.BAD_REQUEST) {
    if (response.details && !isEmpty(response.details)) {
      let error = { data: response.details, type: response.code, message: response.message || '' };
      if (response.details.buffer) {
        error.data.userMessage = isJsonString(response.details.buffer) ?
          JSON.parse(response.details.buffer).userMessage
          : response.details.buffer.userMessage;
      }
      return {
        ok: false,
        error,
      };
    } else {
      return {
        ok: false,
        error: {
          data: response.message || response.translatedMessage || response.code,
          type: response.code,
        },
      };
    }
  }
  if (response.status === ResponseStatus.VALIDATION_ERROR) {
    if (response.details) {
      return { ok: false, error: { data: response.details, type: response.code } };
    }
    return {
      ok: false,
      error: { data: response.message, type: response.code },
    };
  }

  if (response.status === ResponseStatus.SUCCESS && response && response.source_data !== null) {
    return { ok: true, data: response };
  }

  const isInvalidCredentials = response.code === ErrorTypes.INVALID_CREDENTIALS;
  const isSessionExpird = response.code === ErrorTypes.SESSION_EXPIRED;
  const isTokenDisabled = [
    ErrorTypes.TOKEN_NOT_REGISTRED,
    ErrorTypes.TOKEN_RECALLED_OR_DISABLED,
    ErrorTypes.JWT_EXPIRED,
    ErrorTypes.REFRESH_JWT_EXPIRED,
  ].includes(response.code);
  if ((response.status === ResponseStatus.FORBIDDEN && isInvalidCredentials) ||
    (response.status === ResponseStatus.UNAUTHORIZED && (isSessionExpird || isTokenDisabled))) {
    redirectToJoin();
  }

  return {
    ok: false,
    error: { data: response.message || response.translatedMessage, type: response.code },
  };
}
