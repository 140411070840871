import { handleFetching } from 'symbiote-fetching';

import { actions } from '../symbiotes';
import { accountApi } from '../api';
import { getResponse } from '../../common/response';

export const sendTokenToConfirmation = token =>
  handleFetching(actions.fetchConfirmEmail, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.sendTokenToConfirmationEmail, token);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error), type: 'unknown_error' } };
      }
    },
  });
