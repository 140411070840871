import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';

function InvalidEmailTokenView({ classes, navigate }) {
  return (
    <div>
      <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
        <Typography className={classes.marginRight} variant="h4">
          Email verification
        </Typography>
      </Grid>

      <Typography className={classes.titleSignIn} variant="body2">
        Invalid email confirmation link.
      </Typography>

      <Button fullWidth className="normal" size="large" onClick={() => navigate('/join')}>
        Go to login page
      </Button>
    </div>
  );
}

InvalidEmailTokenView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const InvalidEmailToken = InvalidEmailTokenView;
