import { withStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, InputBase, Typography, Paper, InputLabel, Grid, FormControl, CssBaseline } from '@mui/material';

import { ErrorDialog } from 'ui';

import { getEmail } from '../effects/get-email';
import { changePassword } from '../effects/change-password';
import { PassRequire } from '../components/pass-requirements';
import { PassRepeat } from '../components/pass-repeat';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
  },
  formControlSignIn: {
    maxWidth: '435.2px',
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  subTitle: {
    color: '#9FA4AE',
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
  pswd_info: {
    marginTop: '10px',
    fontSize: '.875em',
  },
  invalid: {
    color: 'red',
  },
  valid: {
    color: '#44d437',
  },
  none: {
    display: 'none',
  },
});

const mapDispatchToProps = dispatch => ({
  changePassword: (token, password) => dispatch(changePassword, token, password),
  getEmail: token => dispatch(getEmail, token),
});

function ResetPasswordPageView({ classes, history, changePassword, getEmail }) {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ repeatPassword, setRepeatPassword ] = useState('');
  const [ error, setError ] = useState('');
  const [ errorType, setErrorType ] = useState('');
  const [ errorShow, setErrorShow ] = useState(false);
  const [ isSamePass, setIsSamePass ] = useState(false);
  const [ isEqual, setIsEqual ] = useState(false);
  const [ isMinLength, setIsMinLength ] = useState(false);
  const [ isHaveLower, setIsHaveLower ] = useState(false);
  const [ isHaveUpper, setIsHaveUpper ] = useState(false);
  const [ isHaveNumber, setIsHaveNumber ] = useState(false);

  let { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const email = async () => {
      const { ok, error, data } = await getEmail(token);
      if (ok) {
        setEmail(data.Email);
      } else {
        setError(error.data);
        setErrorType(error.type);
        setErrorShow(true);
      }
    };
    email();
  }, [ getEmail, token ]);

  useEffect(() => {
    if (password.toLocaleLowerCase() === email.toLocaleLowerCase()) {
      setIsEqual(true);
    } else {
      setIsEqual(false);
    }

    if (password.length >= 6) {
      setIsMinLength(true);
    } else {
      setIsMinLength(false);
    }

    if (password.match(/[A-Z]/)) {
      setIsHaveUpper(true);
    } else {
      setIsHaveUpper(false);
    }

    if (password.match(/[a-z]/)) {
      setIsHaveLower(true);
    } else {
      setIsHaveLower(false);
    }

    if (password.match(/\d/)) {
      setIsHaveNumber(true);
    } else {
      setIsHaveNumber(false);
    }
    if (password === repeatPassword) {
      setIsSamePass(true);
    } else {
      setIsSamePass(false);
    }
  }, [ email, password, repeatPassword ]);

  const validPass = useMemo(
    () => !isEqual && isMinLength && isHaveLower && isHaveUpper && isHaveNumber,
    [ isEqual, isMinLength, isHaveLower, isHaveUpper, isHaveNumber ]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { ok, error } = await changePassword(token, password);
      if (ok) {
        navigate('/join');
      } else {
        setError(error.data.message);
        setErrorShow(true);
      }
    } catch (e) {
      throw e;
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorShow(false);
    if (errorType === 'invalid_change_password_token') {
      navigate('/join');
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.container}>
        <Paper className={classes.paperSignIn}>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography className={classes.marginRight} variant="h4">
              Enter new password
            </Typography>
          </Grid>

          <div className={classes.form}>
            <Grid container className={classes.formControlSignIn}>
              <FormControl fullWidth margin="normal">
                <InputLabel className={classes.inputLabel} htmlFor="password">
                  <Typography variant="body2">Password</Typography>
                </InputLabel>
                <InputBase
                  required
                  autoComplete="current-password"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputField,
                  }}
                  id="password"
                  inputProps={{
                    maxLength: '30',
                    minLength: '6',
                    autoComplete: 'true',
                  }}
                  name="password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />

                <PassRequire
                  classes={classes}
                  display={password && !validPass}
                  equal={isEqual}
                  length={isMinLength}
                  lower={isHaveLower}
                  number={isHaveNumber}
                  upper={isHaveUpper}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel className={classes.inputLabel} htmlFor="repeat-password">
                  <Typography variant="body2">Repeat password</Typography>
                </InputLabel>
                <InputBase
                  required
                  autoComplete="current-password"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputField,
                  }}
                  id="repeat-password"
                  inputProps={{
                    maxLength: '30',
                    minLength: '6',
                    autoComplete: 'true',
                  }}
                  name="repeat-password"
                  type="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                />
                <PassRepeat classes={classes} display={repeatPassword && !isSamePass} same={isSamePass} />
              </FormControl>
            </Grid>
            <Grid container alignItems="flex-start" justifyContent="center">
              <Button fullWidth className="normal" disabled={!validPass || !isSamePass} size="large" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </div>
        </Paper>
      </div>
      <ErrorDialog handleClose={handleCloseErrorDialog} message={error} show={errorShow} />
    </main>
  );
}

ResetPasswordPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ResetPasswordPage = connect(null, mapDispatchToProps)(withStyles(ResetPasswordPageView, styles));
