import { withStyles } from 'tss-react/mui';
import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

import { Modal } from './modal';

const styles = theme => ({
  root: {
    width: 'auto',
    marginTop: '10px',
    marginBottom: '20px',
    wordBreak: 'break-word',
  },
  buttonMargin: {
    margin: '16px 20px',
  },
  justifyButton: {
    padding: '8px 0',
    justifyContent: 'center',
  },
});
const MessageView = ({ classes, open, children, dialogTitle = 'Errors', setOpen, closeText = 'Close', titleVariant = 'h4' }) => (
  <Modal handleClose={setOpen} open={open} title="Message">
    <DialogTitle component="div" id="form-dialog-title">
      <Typography variant={titleVariant}>{dialogTitle}</Typography>
    </DialogTitle>
    <DialogContent>
      <div className={classes.root}>{children}</div>
    </DialogContent>
    <DialogActions classes={{ root: classes.justifyButton }}>
      <Button fullWidth className="normal" size="large" onClick={setOpen}>
        {closeText}
      </Button>
    </DialogActions>
  </Modal>
);

export const Message = withStyles(MessageView, styles);
