import { useCallback, useEffect } from 'react';

export const useAlertNotificationService = ({ alert, updateAlertNotifications }) => {
  const alertNotify = useCallback(
    (item) => {
      updateAlertNotifications(item);
    },
    [ updateAlertNotifications ]
  );

  useEffect(() => {
    if (alert) {
      alertNotify(alert);
    }
  }, [ alert, alertNotify ]);
};
