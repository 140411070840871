import React from 'react';

import { commonRoutes, NotFoundPage } from './features/common';
import { authRoutes } from './features/auth';

export const routes = [
  ...commonRoutes(),
  ...authRoutes(),
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
