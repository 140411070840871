import { handleFetching } from 'symbiote-fetching';

import { actions } from '../symbiotes';
import { sessionApi } from '../api';
import { actions as accountActions } from '../../account/symbiotes';

export const logout = () =>
  handleFetching(actions.fetchSignOut, {
    noThrow: false,
    async run(dispatch) {
      try {
        const data = await dispatch(sessionApi.drop);
        if ((data.status === 200 && !data.code) || data.status === 401) {
          localStorage.setItem('refreshToken', JSON.stringify(''));
          localStorage.setItem('accessToken', JSON.stringify(''));
          await dispatch(accountActions.setClient(null));
          await dispatch(actions.auth.reset());
          await dispatch(actions.auth.set({ authenticated: false }));
          return { ok: true, data };
        }
        return { ok: false, result: data && data.code };
      } catch (error) {}
    },
  });
