export const clearPosition = {
  check: false,
  needFunds: 0,
  needVNX: 0,
  balanceFunds: 0,
  quantityVNX: 0,
  availFunds: 0,
  availVNX: 0,
};

export const allColumnsAssets = [
  {
    name: 'ticker',
    title: 'Asset',
  },
  {
    name: 'balance',
    title: 'Balance',
    tooltip: 'Total amount',
  },
  {
    name: 'estimatedValue',
    title: 'Estimated value, €',
  },
];

export const symbolsModel = {
  EUR: {
    color: '#B3BCC5',
    fullName: 'Euro',
  },
  CHF: {
    color: '#FFE7E7',
    fullName: 'Swiss Franc',
  },
  ETH: {
    color: '#8EA6FF',
    fullName: 'ETH',
  },
  BTC: {
    color: '#F99E30',
    fullName: 'BTC',
  },
  USD: {
    symbol: '$',
    color: '#00A2DB',
    fullName: 'US dollar',
  },
  VNXLU: {
    color: '#00BDFF',
    fullName: 'VNXLU',
  },
  USDC: {
    color: '#2775CA',
    fullName: 'USDC',
  },
  GOLD: {
    color: '#E9C93A',
    fullName: 'Gold',
  },
  'GOLD Structured Deposit': {
    color: '#CEC6A2',
    fullName: 'Gold Staking',
  },
  'USDC Structured Deposit': {
    color: '#626870',
    fullName: 'USDC Staking',
  },
  DGR: {
    color: '#AE8E41',
  },
  FRT: {
    color: '#EBE1C0',
  },
};

export const AccountTypes = {
  VNX: 'VNX',
  STRUCTURED_PRODUCTS: 'StructuredProducts',
  TRADING: 'Trading',
  FUNDS: 'Funds',
  DCR: 'DCR',
};
