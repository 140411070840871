import { createSelector } from 'reselect';

import { AssetSymbols } from 'features/common/models';

export const addressesRootSelector = createSelector(
  root => root.addresses,
  addresses => addresses
);

export const walletsSelector = createSelector(addressesRootSelector, addresses => addresses?.wallets);
export const bankDetailWalletsSelector = createSelector(addressesRootSelector, addresses => addresses?.bankDetailWallets);
export const walletTypessSelector = createSelector(addressesRootSelector, addresses => addresses?.walletTypes);
export const withdrawalAddressesSelector = createSelector(addressesRootSelector, addresses => addresses?.withdrawalAddresses);

export const withdrawalAddressSymbolsSelector = createSelector(addressesRootSelector, addresses => addresses?.withdrawalAddressSymbols);

export const defaultSymbolSelector = createSelector(addressesRootSelector, addresses =>
  addresses?.withdrawalAddressSymbols.find(item => item.currency === AssetSymbols.EUR)
);

export const vaspsSelector = createSelector(addressesRootSelector, addresses => addresses?.vasps);

export const defaultVaspSelector = createSelector(addressesRootSelector, addresses => addresses?.vasps?.[0]);

export const deleteWalletLoadingSelector = createSelector(addressesRootSelector, addresses => addresses.fetchDeleteWallet.status);

export const createWalletLoadingSelector = createSelector(addressesRootSelector, addresses => addresses.fetchCreateWallet.status);
export const sendTokenAddressLoadingSelector = createSelector(addressesRootSelector, addresses => addresses.fetchConfirmationAddress.status);
export const withdrawalSettingsSelector = createSelector(addressesRootSelector, addresses => addresses.withdrawalSettings);
