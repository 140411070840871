import { withStyles } from 'tss-react/mui';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, CssBaseline } from '@mui/material';

import { ErrorDialog } from 'ui';
import { getAllTerms } from 'features/terms/effects';
import { allTermsSelector } from 'features/common/selectors';
import { useTitle } from 'features/common/hooks';

import { signUp } from '../effects/sign-up';
import { SignUpForm, TermsForm } from '../components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(7),
    backgroundColor: '#25272B',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'inherit',
    borderRadius: '8px',
    '@media screen and (max-device-width: 460px)': {
      padding: theme.spacing(4),
    },
  },
  paper2: {
    position: 'relative',
    display: 'flex',
    borderRadius: '8px',
    maxWidth: '1160px',
    flexDirection: 'column',
    padding: theme.spacing(7),
    backgroundColor: '#25272B',
    margin: '0 10%',
    '@media screen and (max-device-width: 460px)': {
      padding: theme.spacing(4),
      margin: '0 0',
    },
  },
});

const mapStateToProps = state => ({
  allTerms: allTermsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onSignUp: signupData => dispatch(signUp, signupData),
  getAllTerms: () => dispatch(getAllTerms),
});

const queryString = search => new URLSearchParams(search);

const SignUpPageView = ({ classes, allTerms, onSignUp, getAllTerms }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useTitle('Sign Up');

  const [ errors, setErrors ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);
  const [ proceed, setProceed ] = useState(false);
  const [ form, setForm ] = useState({});

  useEffect(() => {
    getAllTerms();
  }, [ getAllTerms ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const ref = queryString(location?.search).get('ref') || undefined;

    const { ok, error } = await onSignUp({ ...form, ref });

    if (ok) {
      navigate('/sign-up/success');
    } else {
      setErrors(error.data);
      setErrorShow(true);
      setProceed(false);
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Box className={classes.container}>
        <Paper className={!proceed ? classes.paper : classes.paper2}>
          {!proceed ? <SignUpForm form={form} handleProceed={setProceed} submitForm={setForm} /> : <TermsForm handleSubmit={handleSubmit} terms={allTerms} />}
        </Paper>
      </Box>
      <ErrorDialog handleClose={setErrorShow} message={errors} show={errorShow} />
    </main>
  );
};

SignUpPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SignUpPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(SignUpPageView, styles));
