import { ErrorTypes } from './models';

export class TaskQueue {
  constructor() {
    this.tasks = [];
    this.isRefreshing = false;
    this.busy = false;
  }

  setIsRefreshing(bool) {
    this.isRefreshing = bool;
  }

  clearTasks() {
    this.tasks = [];
  }

  addTask(task) {
    return new Promise((resolve) => {
      this.tasks.push(async (returnErrorStatus = false) => {
        const result = returnErrorStatus ? { status: 401, code: ErrorTypes.JWT_EXPIRED } : await task();
        resolve(result);
      });
    });
  }

  addTaskAndRun(task) {
    return new Promise((resolve) => {
      this.tasks.push(async (returnErrorStatus = false) => {
        const result = returnErrorStatus ? { status: 401, code: ErrorTypes.JWT_EXPIRED } : await task();
        resolve(result);
      });
      this.run();
    });
  }

  async run(returnErrorStatus = false) {
    if (this.busy) return;

    this.busy = true;
    while (this.tasks.length) {
      const task = this.tasks.shift();

      try {
        task(returnErrorStatus);
      } catch (error) {
        console.error(error);
      } finally {
      }
    }
    this.busy = false;
  }
}
