import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';
// export const initialPositionConversion =  {
//     "check": true,            - хватает ли средств для исполнения конверсионного ордера
//     "sellTicker": "ETH",      - тикер актива, который продают
//     "buyTicker": "EUR",       - тикер актива, который покупают
//     "direction": "Sell",      - направление обмена криптовалюты, sell - криптовалюту продают, buy - криптовалюту покупают
//     "rate": 2768.4,           - курс обмена криптовалюта/EUR
//     "balanceSell": 0.272132,  - баланс продаваемого актива до сделки
//     "needSell": 0.12345,      - требуемое количество актива для сделки
//     "availSell": 0.148682,    - баланс продаваемого актива после сделки
//     "balanceBuy": 1000000,    - баланс покупаемого актива до сделки
//     "availBuy": 1000341.76,   - баланс покупаемого актива после сделки
//     "valueBuy": 341.76        - количество покупаемого актива, которое будет получено в результате сделки
//   }
export const initialState = {
  fetchOne: initialFetching,
  fetchWithdraw: initialFetching,
  fetchConversionPosition: initialFetching,
  fetchCryptoAutoConvert: initialFetching,
  fetchClientBankDetails: initialFetching,
  fetchCheckWithdrawalPosition: initialFetching,
  fetchCheckCurrencyAcquiringPositions: initialFetching,
  fetchAcquiringCheckouts: initialFetching,
  fetchAvailCryptoTickers: initialFetching,
  fetchSessionForPaymentIQ: initialFetching,
  fetchPositionDueFee: initialFetching,
  fetchProductPositions: initialFetching,
  wallet: {
    address: '',
  },
  commission: 0,
  positionConversion: {},
  productPositionSymbols: {},
  DGRPositions: [],
  FRTPositions: [],
  currencyPositions: [],
  cryptoPositions: [],
  altcoinPositions: [],
  vnxPositions: [],
  clientBankDetail: {},
  clientBankDetails: [],
  withdrawalPosition: {},
  acquiringPositions: {},
  acquiringCheckouts: {},
  countries: [],
  acquiringCheckoutsPayment: {},
  availCryptoTickers: [],
  structuredDepositPositions: [],
  positionDueFee: {},
  availFiat: [],
  availAssets: [],
  sessionForPaymentIQ: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchWithdraw: createFetching('fetchWithdraw'),
  fetchConversionPosition: createFetching('fetchConversionPosition'),
  fetchCryptoAutoConvert: createFetching('fetchCryptoAutoConvert'),
  fetchClientBankDetails: createFetching('fetchClientBankDetails'),
  fetchCheckWithdrawalPosition: createFetching('fetchCheckWithdrawalPosition'),
  fetchCheckCurrencyAcquiringPositions: createFetching('fetchCheckCurrencyAcquiringPositions'),
  fetchAcquiringCheckouts: createFetching('fetchAcquiringCheckouts'),
  fetchCountries: createFetching('fetchCountries'),
  fetchAcquiringCheckoutsPayment: createFetching('fetchAcquiringCheckoutsPayment'),
  fetchAvailCryptoTickers: createFetching('fetchAvailCryptoTickers'),
  fetchSessionForPaymentIQ: createFetching('fetchSessionForPaymentIQ'),
  fetchStructuredDepositPositions: createFetching('fetchStructuredDepositPositions'),
  fetchPositionDueFee: createFetching('fetchPositionDueFee'),
  fetchProductPositions: createFetching('fetchProductPositions'),
  setWallet: (state, data) => ({ ...state, wallet: data }),
  setCommission: (state, data) => ({ ...state, commission: data }),
  setPositionConversion: (state, data) => ({ ...state, positionConversion: data }),
  setProductPositionSymbols: (state, data) => {
    const symbols = {};
    let hasAnyPositions = false;
    data.forEach((item) => {
      hasAnyPositions = true;
      symbols[item.symbolId] = {
        ...item,
      };
    });
    if (hasAnyPositions) {
      return { ...state, productPositionSymbols: symbols };
    } else {
      return { ...state };
    }

  },
  setDGRPositions: (state, data) => ({ ...state, DGRPositions: data }),
  setFRTPositions: (state, data) => ({ ...state, FRTPositions: data }),
  setCurrencyPositions: (state, data) => ({ ...state, currencyPositions: data }),
  setCryptoPositions: (state, data) => ({ ...state, cryptoPositions: data }),
  setAltcoinPositions: (state, data) => ({ ...state, altcoinPositions: data }),
  setVnxPositions: (state, data) => ({ ...state, vnxPositions: data }),
  setClientBankDetail: (state, data) => ({ ...state, clientBankDetail: data }),
  setClientBankDetails: (state, data) => ({ ...state, clientBankDetails: data }),
  setWithdrawalPosition: (state, data) => ({ ...state, withdrawalPosition: data }),
  setAcquiringPositions: (state, data) => ({ ...state, acquiringPositions: data }),
  setAcquiringCheckouts: (state, data) => ({ ...state, acquiringCheckouts: data }),
  setCountries: (state, data) => ({ ...state, countries: data }),
  setAcquiringCheckoutsPayment: (state, data) => ({ ...state, acquiringCheckoutsPayment: data }),
  setAvailCryptoTickers: (state, data) => ({ ...state, availCryptoTickers: data }),
  setStructuredDepositPositions: (state, data) => ({ ...state, structuredDepositPositions: data }),
  setPositionDueFee: (state, data) => ({ ...state, positionDueFee: data }),
  setAvailFiat: (state, data) => ({ ...state, availFiat: data }),
  setAvailAssets: (state, data) => ({ ...state, availAssets: data }),
  setSessionForPaymentIQ: (state, data) => ({ ...state, sessionForPaymentIQ: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'wallet');
