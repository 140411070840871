import { getDateFormat } from 'lib/date';

export const mapBkToFrLinks = items =>
  items.map(item => ({
    name: item.Name,
    link: item.Link,
    downloaded: item.Downloaded,
  }));

export const mapStructuredProduct = item => ({
  id: item.id,
  name: item.Name,
  underlyingType: item?.UnderlyingAsset?.Type,
  underlyingTicker: item?.UnderlyingAsset?.Ticker,
  rate: item.MaxCouponRate === item.MinCouponRate ? item.MaxCouponRate : `${item.MinCouponRate} - ${item.MaxCouponRate}`,
  promoDescription: item.PromoDescription,
  description: item.Description,
  minCouponRate: item.MinCouponRate,
  logo: item.LogoSp,
});

export const mapStructuredProducts = items => items.map(item => mapStructuredProduct(item));

export const mapProductOffer = item => ({
  id: item.id,
  assetId: item.AssetId,
  maturity: item.Maturity,
  status: item.Status,
  rate: item.CouponRate,
  risk: item.StructuredProduct.CreditRisk,
  successId: item.StructuredProduct.UnderlyingAssetId,
  fee: item.SuccessFeeRate,
  ticker: item.Asset.Ticker,
  assetType: item.StructuredProduct.UnderlyingAsset.Type,
  minimumAmount: item.MinimumAmount,
});
export const mapProductOffers = items => items.map(item => mapProductOffer(item));
export const mapStructuredDeposit = (item) => {
  if (item) {
    return {
      id: item.OfferAssetId,
      underlyingId: item.UnderlyingAssetId,
      amount: item.Amount,
      redemptionAmount: item.RedemptionAmount,
      maturityDate: getDateFormat(item.MaturityDate),
      type: item.OfferAssetType,
      underlyingType: item.UnderlyingAssetType,
      underlyingAssetTicker: item.UnderlyingAssetTicker,
      offerAssetTicker: item.OfferAssetTicker,
      successFee: item.SuccessFee,
      successFeeVnxlu: item.SuccessFeeVnxlu,
      maturityDateTimestamp: Date.parse(item.MaturityDate),
      logoSp: item.LogoSp,
    };
  } else {
    return {};
  }
};
export const mapStructuredDeposits = items => items.map(item => mapStructuredDeposit(item));

const mapPromoCardDStrade = (item) => {
  if (!item) return null;
  return {
    status: item.Status,
    blockchainId: item.BlockchainId,
    direction: item.Direction,
    type: item.Type,
    id: item.id,
    minQuantity: item.MinQuantity,
    maxQuantity: item.MaxQuantity,
  };
};

const mapPromoCard = item => ({
  ...item,
  AgencyBuy: mapPromoCardDStrade(item.AgencyBuy),
  AgencySell: mapPromoCardDStrade(item.AgencySell),
  PropBuy: mapPromoCardDStrade(item.PropBuy),
  PropSell: mapPromoCardDStrade(item.PropSell),
  logo: item.ui?.icon,
});

export const mapPromoCards = items => items.map(item => mapPromoCard(item));

export const mapDSProduct = item => ({
  id: item.id,
  symbolId: item.SymbolId,
  type: item.Type,
  name: item.Name,
  instrumentType: item.InstrumentType,
  markupType: item.MarkupType,
  subscriptionBook: item.SubscriptionBook,
  status: item.Status,
  preMoneyValuation: item.PreMoneyValuation,
  promotionalDescription: item.PromotionalDescription,
  ticker: item.Ticker,
  currencyId: item.CurrencyId,
  tradeBuyId: item.TradeBuyId,
  tradeSellId: item.TradeSellId,
  minQuantityBuy: item.MinQuantityBuy,
  minQuantitySell: item.MinQuantitySell,
  isAgencyBuy: item.AgencyBuy,
  isAgencySell: item.AgencySell,
  buyPrice: item.BuyPrice,
  sellPrice: item.SellPrice,
  buyTicker: item.BuyTicker,
  sellTicker : item.SellTicker ,
  tradeType: item.TradeType,
  presentations: item.Presentations,
  offers: item.Offers?.map(trade => ({
    status: trade.Status,
    blockchainId: trade.BlockchainId,
    direction: trade.Direction,
    type: trade.Type,
    id: trade.id,
    minQuantity: trade.MinQuantity,
    maxQuantity: trade.MaxQuantity,
    currencyTicker: trade.Currency?.Ticker,
    availPaymentTickers: trade.AvailPaymentTickers ?? [],
  })),
  ui: {
    icon: item.ui?.icon,
    largeLogo: item.ui?.large_logo,
  },
});

// New - выводить все оферинги с bidding status = roadshow/ bidding не заведен и Offering status = Ongoing; Direct sale offer status = New
// Open - выводить все оферинги c bidding status = open, Direct sale offer status = Open
// Finished - выводить все оферинги c bidding status = closed, suspended, canceled, settlement complete, settlement pending; Direct sale offer status = Close

export const OfferingStatusView = {
  idle: 'Idle',
  queue: 'New',
  new: 'New',
  roadshow: 'New',
  close: 'Close',
  finished: 'Finished',
  closed: 'Finished',
  open: 'Open',
  ongoing: 'Active',
  suspended: 'Finished',
  canceled: 'Finished',
  completed: 'Finished',
};
