import { Navigate } from 'react-router-dom';
import React from 'react';

import { SignInPage, ForgetPasswordPage, ResetPasswordPage, RegisterPage, SignUpPage, SuccessRegisterPage, ConfirmEmailPage } from './pages';

export const authRoutes = () => [
  {
    path: '/join',
    element: <SignInPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
  {
    path: '/forget',
    element: <ForgetPasswordPage />,
  },
  {
    path: '/change-password',
    element: <ResetPasswordPage />,
    children: [
      {
        path: ':token',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: '/register',
    element: <RegisterPage />,
    children: [
      {
        path: ':token',
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: '/confirm-email',
    element: <ConfirmEmailPage />,
    children: [
      {
        path: ':token',
        element: <ConfirmEmailPage />,
      },
    ],
  },
  {
    path: '/logout',
    element: () => <Navigate to="/join" />,
  },
  {
    path: '/sign-up/success',
    element: <SuccessRegisterPage />,
  },
];
