import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchOneOfferings: initialFetching,
  fetchDocuments: initialFetching,
  filter: [],
  disclosureItems: [],
  disclosureOfferingsItems: [],
  currentDisclosureOffering: {},
  error: { ok: true, data: '' },
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchDocuments: createFetching('fetchDocuments'),
  setDisclosureItems: (state, data) => ({ ...state, disclosureItems: data }),
  setDisclosureOfferingsItems: (state, data) => ({ ...state, disclosureOfferingsItems: data }),
  setCurrentDisclosureOffering: (state, data) => ({ ...state, currentDisclosureOffering: data }),
  setError: (state, data) => ({ ...state, error: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'disclosure');
