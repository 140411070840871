import { api } from 'features/common';

export const accountApi = {
  getClient: () => api.get('/ui/nokyc/clients/me'),
  checkPosition: query => api.get('/ui/offering/bid/check-positions', query),
  getSymbols: query => api.get('/ui/symbols', query),
  getPublicSymbols: query => api.get('/ui/nokyc/symbols', query),
  getClientPnL: () => api.get('/ui/clients/pnl'),
  getPublicClientPnL: () => api.get('/ui/nokyc/clients/pnl'),
  getClientSymbolsMyAssets: () => api.get('/ui/symbols/myAssetsAggregated'),
  getPublicSymbolsMyAssets: () => api.get('/ui/nokyc/symbols/myAssetsAggregated'),
};
