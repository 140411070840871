/* eslint-disable no-useless-escape */
/* FIXME убрать правило выше, решить проблему регексп.
 */
import { withStyles } from 'tss-react/mui';
import React from 'react';

const styles = theme => ({
  link: {
    width: '100%',
    fontWeight: 600,
    color: '#FFFFFF',
    '&:link': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
    '&:active': {
      color: '#FFFFFF',
    },
  },
  link2: {
    textDecoration: 'underline',
    width: '100%',
    fontWeight: 600,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:link': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
    '&:active': {
      color: '#FFFFFF',
    },
  },
});

export const UrlRecognizeView = ({ classes, item, onClickLink, isPush = false }) => {
  //const regexpSomeUrl = /(.*|.?)\[(.+)\]\(([^ ]+)?\)(.*|.?)/i
  //const regexpSome = /\[(.+)\]\(([^\n\r]+)?\)/i
  let alltext = item;
  //const elements = alltext.match(/\[.*?\)/g)
  const elements = alltext.match(/(\[.*?\))|(\[.*?\>)/gm);
  if (elements != null && elements.length > 0) {
    for (let el of elements) {
      let txt = el.match(/\[(.*?)\]/)?.[1]; //get only the txt
      let url = el.match(/\((.*?)\)/)?.[1]; //get only the link
      let interlink = el.match(/\<(.*?)\>/)?.[1]; //get only the interlink
      if (onClickLink && url) {
        alltext = alltext.replace(
          el,
          `<a class="${
            isPush ? classes.link : 'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
          }" onClick=${onClickLink} href="${url}">
              ${txt}
            </a>`
        );
      } else if (!onClickLink && url) {
        alltext = alltext.replace(
          el,
          `<a class="${isPush ? classes.link : 'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'}" href="${url}">
              ${txt}
            </a>`
        );
      } else if (onClickLink && interlink) {
        alltext = alltext.replace(
          el,
          `<span class="${
            isPush ? classes.link2 : 'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
          }" onClick="window.Intercom('show'); return false;" >
              ${txt}
            </span>`
        );
      } else if (!onClickLink && interlink) {
        alltext = alltext.replace(
          el,
          `<span class="${
            isPush ? classes.link2 : 'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary'
          }" onClick="window.Intercom('show'); return false;" >
              ${txt}
            </span>`
        );
      }
    }
    return <div className={classes.paper1} dangerouslySetInnerHTML={{ __html: alltext }} />;
  } else {
    return item;
  }
};

export const UrlRecognize = withStyles(UrlRecognizeView, styles);
