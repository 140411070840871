import { actions } from '../symbiotes';
import { accountApi } from '../api';
import { getResponse } from '../../common/response';

export const resendTokenToConfirmation =
  ({ login }) =>
    async (dispatch) => {
      try {
        const response = await dispatch(accountApi.resendTokenToConfirmationEmail, {
          login,
        });

        if (response && !response.code) {
          await dispatch(actions.selfRegistration.reset());
          dispatch(actions.selfRegistration.set(response));
          return getResponse(response);
        }
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error), type: 'unknown_error' } };
      }
    };
