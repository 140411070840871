import cet from 'timezone/Europe';
import tz from 'timezone';
import { getTimezoneOffset } from 'date-fns-tz';

const timeZoneBerlin = getTimezoneOffset('Europe/Berlin', new Date().getTime());
const localTimeZone = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone.toString());

export function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

export let oneDay = 24 * 60 * 60 * 1000;
export function getDayRemaining({ to }) {
  let firstDate = new Date();
  let secondDate = new Date(to);

  const days = (secondDate.getTime() - firstDate.getTime()) / oneDay;

  if (days > 0) {
    return Math.round(Math.abs(days));
  }

  return 0;
}

export function getDayProgress({ from, to }) {
  let currentDate = new Date();
  let openingDate = new Date(from);
  let closingDate = new Date(to);
  let days = Math.round((currentDate.getTime() - openingDate.getTime()) / oneDay);
  let rangeDays = Math.round((closingDate.getTime() - openingDate.getTime()) / oneDay);
  const allDays = (100 * days) / rangeDays;
  if (allDays > 0) {
    return Math.round(Math.abs(allDays));
  }

  return 0;
}

export const convertYearToDate = (year) => {
  if (year === null || year === '' || year === undefined) {
    return null;
  }

  const today = new Date();
  return new Date(`${year}/${today.getMonth() + 1}/${today.getDate()}`);
};

export function convertDateTimestamp(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  const eu = tz(cet);
  return eu(`${dateTime.getFullYear()}-${addZeroBefore(dateTime.getMonth() + 1)}-${addZeroBefore(dateTime.getDate())} 03:00`, 'Europe/Berlin');
}

export function convertDateTimeTimestamp(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  const eu = tz(cet);
  return eu(
    `${dateTime.getFullYear()}-${addZeroBefore(dateTime.getMonth() + 1)}-${addZeroBefore(dateTime.getDate())} ${getTimeFormat(dateTime)}`,
    'Europe/Berlin'
  );
}

export const getTimeFormat = (dateString) => {
  if (dateString === null || dateString === undefined || dateString === '') {
    return '';
  }
  let date = new Date(dateString);

  let minutes = addZeroBefore(date.getMinutes());
  let hours = addZeroBefore(date.getHours());

  return `${hours}:${minutes}`;
};

export const getDateMonthYearFormat = (date) => {
  const eu = tz(cet);
  let dateFormat = new Date(eu(tz(date), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin'));
  return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getFullYear()}`;
};

export const getDayMonthFormat = (date) => {
  const eu = tz(cet);
  let dateFormat = new Date(eu(tz(date), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin'));
  return `${dateFormat.getDate()} ${monthNames[dateFormat.getMonth()]}`;
};

export const getDateFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%d.%m.%Y', 'Europe/Berlin');
};

export const getDateDayMonthYearTimeFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%a, %d %b   %Y, %H:%M CET', 'Europe/Berlin');
};

export const getDateTimeFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%d.%m.%Y %H:%M', 'Europe/Berlin');
};

export const getDateTimeWithoutYearFormat = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%d.%m %H:%M', 'Europe/Berlin');
};

export const getDateYearFormat = (date) => {
  if (date) {
    let dateFormat = new Date(date);
    return dateFormat.getFullYear();
  } else {
    return '';
  }
};

export const getNowCET = () => {
  const eu = tz(cet);
  return eu(tz(new Date()), 'Europe/Berlin');
};
export const getNowHMCET = () => {
  const eu = tz(cet);
  return eu(tz(new Date()), 'Europe/Berlin', '%R');
};

export function convertDateTimeToCET(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return '';
  }
  const eu = tz(cet);
  return new Date(eu(tz(dateTime), '%Y/%m/%d %H:%M:%S', 'Europe/Berlin'));
}

function calcDistanceTime(t) {
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(t / (1000 * 60 * 60 * 24 * 7));
  const months = Math.floor(t / (1000 * 60 * 60 * 24 * 30));

  return {
    total: t,
    months,
    weeks: weeks,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

export function getTimeRemaining(endtime) {
  const t = new Date(endtime).getTime() - getNowCET();
  return calcDistanceTime(t);
}

export function getPastTime(endtime) {
  const t = getNowCET() - new Date(endtime).getTime();
  return calcDistanceTime(t);
}

export const getFormatPastTime = (expire) => {
  let expireString = '';
  if (expire.months > 0) return `${expire.months} months ago`;
  if (expire.weeks > 0) return `${expire.weeks} weeks ago`;
  if (expire.days > 0) return `${expire.days} days ago`;
  if (expire.hours > 0) return `${expireString} ${expire.hours} hours ago`;
  if (expire.minutes > 0) return `${expireString} ${expire.minutes} minutes ago`;
  if (expireString === '' && expire.seconds <= 59 && expire.seconds > 0) expireString = 'less than a minute ago';
  return expireString;
};

export const getDateTimeFormatFromString = (dateString) => {
  if (dateString === null || dateString === undefined || dateString === '') {
    return '';
  }
  const dateTime = new Date(dateString);
  const date = `${dateTime.getFullYear()}/${addZeroBefore(dateTime.getMonth() + 1)}/${addZeroBefore(dateTime.getDate())} ${getTimeFormat(dateTime)}`;

  return `${getDateTimeFormat(new Date(date))} CET`;
};

export const getDateTimeWithoutYearFormatFromString = (dateString) => {
  if (dateString === null || dateString === undefined || dateString === '') {
    return '';
  }
  const dateTime = new Date(dateString);
  const date = `${dateTime.getFullYear()}/${addZeroBefore(dateTime.getMonth() + 1)}/${addZeroBefore(dateTime.getDate())} ${getTimeFormat(dateTime)}`;

  return `${getDateTimeWithoutYearFormat(new Date(date))} CET`;
};

export const stringToDate = (dateString) => {
  let dateParts = dateString.split('.');
  let timeParts = dateParts[2].split(' ')[1].split(':');
  dateParts[2] = dateParts[2].split(' ')[0];
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], timeParts[0], timeParts[1]);
};

export const getDayOfWeek = (date) => {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  const eu = tz(cet);
  return eu(tz(date), '%-w', 'Europe/Berlin');
};

export function convertDateTimestampFilter(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  return new Date(new Date(dateTime).getTime() + localTimeZone - timeZoneBerlin).getTime();
}

export function convertDateTimeTimestampFilter(dateTime) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return null;
  }
  return new Date(new Date(dateTime) - new Date('1970-01-01 00:00:00')).getTime();
}
export function getDateInNDays(NDays) {
  const today = new Date();
  today.setDate(today.getDate() + NDays);
  return getDateFormat(today);
}
