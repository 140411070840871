import React from 'react';

const TwoFactorAuthPage = React.lazy(() => import('./pages/2fa'));

export const twoFactorAuthRoutes = [
  {
    path: '/2fa',
    element: <TwoFactorAuthPage />,
  },
];
