import React from 'react';

const AccountPage = React.lazy(() => import('./account-page'));

export const accountRoutes = [
  {
    path: '/account',
    element: <AccountPage />,
  },
];
