export const NotificationStatus = {
  NEW: 'new',
  RED: 'red',
};

export const UserAuthStatuses = {
  LOGIN: 'login',
  LOGOUT: 'logout',
};

export const PriceUpdateStatuses = {
  FIAT_EURO: 'onFiatEuroChanged',
  GOLD_QUOTE: 'onGoldQuoteChanged',
  CRYPTO_RATES: 'onCryptoRatesChanged',
};
