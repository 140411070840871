import { getDayRemaining } from '../../lib/date';

import { KYCResults } from './models';

export const getKYCStatus = (KYCReport) => {
  if (KYCReport) {
    const result = KYCReport.Result;

    if (result === KYCResults.NEW) {
      return KYCResults.NEW;
    }

    if (result === KYCResults.PASSED) {
      return KYCResults.PASSED;
    }

    if (result === KYCResults.REJECTED) {
      return KYCResults.REJECTED;
    }

    if (result === KYCResults.RETRY) {
      return KYCResults.RETRY;
    }
  }
  return KYCResults.IDLE;
};

export const getIsExpireSoon = (KYCReport, warningDay) => getDayRemaining({ to: KYCReport.ExpireAt }) <= warningDay;
