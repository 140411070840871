import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid } from '@mui/material';

import { StatusSignUp } from '../constants';

function EmailConfirmedView({ classes, navigate, status }) {
  return (
    <div>
      {status === StatusSignUp.CONFIRM && (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography className={classes.marginRight} variant="h4">
              Email verification
            </Typography>
          </Grid>
          <Typography className={classes.titleSignIn} variant="body2">
            Your email has been verified successfully!
          </Typography>
        </Fragment>
      )}
      {status === StatusSignUp.ALREADY_CONFIRMED && (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography variant="h4">Email verification</Typography>
          </Grid>
          <Typography className={classes.titleSignIn} variant="body2">
            Your email address has already been confirmed.
          </Typography>
        </Fragment>
      )}
      <Grid container justifyContent="center">
        <Button fullWidth className="normal" size="large" onClick={() => navigate('/join')}>
          Go to login page
        </Button>
      </Grid>
    </div>
  );
}

EmailConfirmedView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const EmailConfirmed = EmailConfirmedView;
