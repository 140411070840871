import { createSelector } from 'reselect';

export const authRootSelector = createSelector(
  root => root.auth,
  auth => auth
);

export const auth2FASelector = createSelector(authRootSelector, auth => auth.Enable2FA);

export const authenticatedSelector = createSelector(authRootSelector, auth => auth.authenticated);

export const authTypeSelector = createSelector(authRootSelector, auth => auth.type);

export const authCientTypeSelector = createSelector(authRootSelector, auth => auth.Client?.Type);

export const authClientClientTypeSelector = createSelector(authRootSelector, auth => auth.Client?.ClientType);

export const authCientKYCReportSelector = createSelector(authRootSelector, auth => auth.Client?.KycReport);

export const authClientIdSelector = createSelector(authRootSelector, auth => auth.ClientId);

export const authClientEligibleSelector = createSelector(authRootSelector, auth => auth.Client?.Eligible);

export const authClientExternalUserId = createSelector(authRootSelector, auth => auth.Client?.KycReport?.ExternalUserId);

export const selfRegistrationLogin = createSelector(authRootSelector, auth => auth.register?.User?.Login);

export const authLoginSelector = createSelector(authRootSelector, auth => auth.Login);

export const authClientSelector = createSelector(authRootSelector, auth => auth.Client);

export const authUserIdSelector = createSelector(authRootSelector, auth => auth.id);

export const authIntercomHashSelector = createSelector(authRootSelector, auth => auth.IntercomHash);

export const authLoadingStatusSelector = createSelector(authRootSelector, auth => auth.fetchAuth?.status);

export const confirmEmailLoadingStatusSelector = createSelector(authRootSelector, auth => auth.fetchConfirmEmail?.status);

export const eventIdleUpdateSelector = createSelector(authRootSelector, auth => auth.eventIdleUpdate);
