import { withStyles } from 'tss-react/mui';
import React from 'react';
import CheckboxMaterial from '@mui/material/Checkbox';

export const Checkbox = withStyles(
  props => <CheckboxMaterial color="default" {...props} />,
  (_theme, _params, classes) => ({
    root: {
      color: '#E3E3E3',
      [`&.${classes.checked}`]: {
        color: '#00BDFF',
      },
    },
    checked: {},
  })
);
