import { withStyles } from 'tss-react/mui';
import React from 'react';

import { ReactComponent as FireIcon } from '../assets/icons/Fire.svg';

const styles = theme => ({
  root: {
    background: '#fff',
    borderRadius: '30px',
    height: 30,
    width: 30,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 15,
    width: 15,
    '& path': {
      fill: '#ED2939',
    },
  },
});

const HotOfferIconView = ({ children, classes, leftValue, topValue }) => (
  <div className={classes.root} style={{ left: `${leftValue}`, top: `${topValue}` }}>
    <FireIcon className={classes.icon} />
  </div>
);

export const HotOfferIcon = withStyles(HotOfferIconView, styles);
