import React from 'react';

import { TabsStructuredProduct } from './models';

const StructuredProductPage = React.lazy(() => import('./structured-product-page'));
const ProductInfoTab = React.lazy(() => import('./components/product-info-tab'));
const DisclosureTab = React.lazy(() => import('../disclosure/components/disclosure-tab'));
const NotFoundPage = React.lazy(() => import('features/common'));

export const structuredProductRoutes = [
  {
    path: '/staking/:id',
    element: <StructuredProductPage />,
    children: [
      {
        path: '',
        element: <ProductInfoTab />,
      },
      {
        path: `${TabsStructuredProduct.productInfo.route}`,
        element: <ProductInfoTab />,
      },
      {
        path: `${TabsStructuredProduct.documentsInfo.route}`,
        element: <DisclosureTab />,
      },
    ],
  },
  {
    path: '/staking/:id/*',
    element: <NotFoundPage />,
  },
];
