import { handleFetching } from 'symbiote-fetching';

import { actions } from '../symbiotes';
import { sessionApi } from '../api';

export const checkAuthenticated = () =>
  handleFetching(actions.fetchAuth, {
    noThrow: false,
    async run(dispatch) {
      const data = await dispatch(sessionApi.me);
      if (data && !data.code) {
        dispatch(actions.auth.set({ ...data, authenticated: true, type: data.Type }));
        return { authenticated: true, type: data.Type };
      } else {
        dispatch(actions.auth.set({ ...data, authenticated: false, type: data?.Type }));
        return { authenticated: false, ...data };
      }
    },
  });
