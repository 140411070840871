import { request, TOKEN_ID } from './request';
import { get, post, put, del } from './api';

export { CommonTemplate } from './components';

export { commonRoutes } from './routes';
export { actions, reducer } from './symbiotes';
export { NotFoundPage } from './not-found-page';
export const api = {
  request,
  get,
  post,
  put,
  del,
  TOKEN_ID,
};
