import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputBase, FormHelperText, Typography, Grid } from '@mui/material';
import { cx } from '@emotion/css';

import { useInterval } from 'lib/timer';

import { StatusSignUp } from '../constants';

const resendPossibleTime = 30;
const sec = 1;

function SuccessRegisterView({ classes, selfRegistrationLogin, resendToken, login, setLogin, status }) {
  const [ isEmailResend, setEmailResend ] = useState(false);
  const [ count, setCount ] = useState(resendPossibleTime);

  useInterval(() => {
    if (count === sec) {
      setEmailResend(true);
    }
    if (!isEmailResend) {
      setCount(count - sec);
    }
  }, 1000);

  const resendEmail = () => {
    if (isEmailResend) {
      setEmailResend(false);
      setCount(resendPossibleTime);
      resendToken();
    }
  };

  return (
    <div>
      {status === StatusSignUp.SUCCESS_SENT ? (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography variant="h4">Email verification</Typography>
          </Grid>
          <Typography className={classes.formControlSignIn} variant="body2">
            Verification email has been sent. Please check your mail box.
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
            <Typography variant="h4">You have been registered</Typography>
          </Grid>
          <Typography className={classes.titleSignIn} variant="body2">
            Congratulations! You have been successfully registered on VNX platform.
          </Typography>
          <Typography className={cx(classes.formControlSignIn, classes.contentFit)} variant="body2">
            We have sent you a verification email, please check it now and click the link to confirm your email. Once done, you can login to the platform and
            complete your profile.
          </Typography>
        </Fragment>
      )}

      {status !== StatusSignUp.SUCCESS_SENT && !selfRegistrationLogin && (
        <Grid container alignItems="center" className={classes.row} justifyContent="space-between" wrap="nowrap">
          <FormControl fullWidth merge="normal">
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="login"
              name="login"
              placeholder="Email"
              type="email"
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
      )}
      <Grid container justifyContent="center">
        {status === StatusSignUp.SUCCESS_SENT && (selfRegistrationLogin || login) ? (
          <Button className="normal" size="large" onClick={resendEmail}>
            {isEmailResend ? 'Resend confirmation email' : `Resend confirmation email in ${count} sec.`}
          </Button>
        ) : (
          <Button className="normal" size="large" onClick={resendEmail}>
            {isEmailResend ? 'Resend confirmation email' : `Resend confirmation email in ${count} sec.`}
          </Button>
        )}
      </Grid>
    </div>
  );
}

SuccessRegisterView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SuccessRegister = SuccessRegisterView;
