import { withStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Paper } from '@mui/material';

import { ErrorDialog, Spinner } from 'ui';

import { confirmEmailLoadingStatusSelector, selfRegistrationLogin } from '../selectors';
import { sendTokenToConfirmation } from '../effects/send-token-to-confirmation';
import { resendTokenToConfirmation } from '../effects/resend-token-to-confirmation';
import { StatusSignUp } from '../constants';
import { InvalidEmailToken, EmailConfirmed, ExpiredEmailToken } from '../components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    borderRadius: '8px',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  subTitle: {
    color: '#9FA4AE',
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
});

const mapDispatchToProps = dispatch => ({
  sendToken: token => dispatch(sendTokenToConfirmation, token),
  resendTokenToConfirmation: data => dispatch(resendTokenToConfirmation, data),
});

const mapStateToProps = state => ({
  selfRegistrationLogin: selfRegistrationLogin(state),
  loading: confirmEmailLoadingStatusSelector(state) === 1,
});
function ConfirmEmailPageView({ classes, sendToken, resendTokenToConfirmation, selfRegistrationLogin, loading }) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [ login, setLogin ] = useState('');
  const [ errors, setErrors ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);
  const [ status, setStatus ] = useState('');

  const resendToken = async () => {
    if (selfRegistrationLogin || login) {
      const { ok, error } = await resendTokenToConfirmation({ login: selfRegistrationLogin || login });
      if (ok) {
        setStatus(StatusSignUp.SUCCESS_SENT);
      } else {
        setErrors(error.data);
        setErrorShow(true);
      }
    }
  };

  const statusSendToken = useCallback(async () => {
    const { ok, error } = await sendToken(token);
    if (ok) {
      setStatus(StatusSignUp.CONFIRM);
    } else {
      if (error.type === StatusSignUp.INVALID_TOKEN) {
        setStatus(StatusSignUp.INVALID_TOKEN);
      }
      if (error.type === StatusSignUp.ALREADY_CONFIRMED) {
        setStatus(StatusSignUp.ALREADY_CONFIRMED);
      }
      if (error.type === StatusSignUp.EXPIRED_TOKEN) {
        setStatus(StatusSignUp.EXPIRED_TOKEN);
      }
      if (
        ![ StatusSignUp.EXPIRED_TOKEN, StatusSignUp.ALREADY_CONFIRMED, StatusSignUp.INVALID_TOKEN, StatusSignUp.CONFIRM, StatusSignUp.SUCCESS_SENT ].includes(
          error.type
        )
      ) {
        setErrors(error.data);
        setErrorShow(true);
      }
    }
  }, [ sendToken, token ]);

  useEffect(() => {
    if (token) {
      statusSendToken();
    }
  }, [ statusSendToken, token ]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.container}>
        <Paper className={classes.paperSignIn}>
          {status === StatusSignUp.INVALID_TOKEN && <InvalidEmailToken classes={classes} navigate={navigate} />}
          {(status === StatusSignUp.ALREADY_CONFIRMED || status === StatusSignUp.CONFIRM) && (
            <EmailConfirmed classes={classes} navigate={navigate} status={status} />
          )}
          {(status === StatusSignUp.EXPIRED_TOKEN || status === StatusSignUp.SUCCESS_SENT) && (
            <ExpiredEmailToken
              classes={classes}
              login={login}
              resendToken={resendToken}
              selfRegistrationLogin={selfRegistrationLogin}
              setLogin={setLogin}
              status={status}
            />
          )}
          <ErrorDialog handleClose={setErrorShow} message={errors} show={errorShow} />
        </Paper>
      </div>
    </main>
  );
}

ConfirmEmailPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};
export const ConfirmEmailPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(ConfirmEmailPageView, styles));
