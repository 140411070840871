import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchStructuredProduct: initialFetching,
  structuredProduct: {},
};

const symbiotes = {
  fetchStructuredProduct: createFetching('fetchStructuredProduct'),
  setStructuredProduct: (state, data) => ({ ...state, structuredProduct: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'structuredProduct');
