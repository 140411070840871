import { createSelector } from 'reselect';

export const notificationsRootSelector = createSelector(
  root => root.notification,
  notification => notification
);

export const notificationsSelector = createSelector(notificationsRootSelector, notification => notification?.notifications);

export const countNewNotificationsSelector = createSelector(notificationsSelector, notifications =>
  notifications?.reduce((acc, item) => (item.status === 'new' ? acc + 1 : acc), 0)
);

export const notificationsSettingsSelector = createSelector(notificationsRootSelector, notification => notification?.settings);

export const notificationsLoadingStatusSelector = createSelector(notificationsRootSelector, notification => notification?.fetchGetNotifications?.status);

export const alertNotificationsSelector = createSelector(notificationsRootSelector, notification => notification?.alertNotifications);

export const eventPushSelector = createSelector(notificationsRootSelector, notification => notification?.eventPush);

export const onFiatEuroChangedEventSelector = createSelector(notificationsRootSelector, notification => notification?.onFiatEuroChangedEvent);
export const onGoldQuoteChangedEventSelector = createSelector(notificationsRootSelector, notification => notification?.onGoldQuoteChangedEvent);
export const onCryptoRatesChangedEventSelector = createSelector(notificationsRootSelector, notification => notification?.onCryptoRatesChangedEvent);
