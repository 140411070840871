import { isJsonString } from 'lib/string';
import { ResponseStatus } from 'features/common/constants';

import { sessionApi } from '../api';

export const refresh = () =>
  async (dispatch) => {
    const refresh_token = JSON.parse(isJsonString(localStorage.getItem('refreshToken')) ? localStorage.getItem('refreshToken') : '""');
    try {
      const response = await dispatch(sessionApi.refresh, {
        refresh_token,
      });

      if (response?.status === ResponseStatus.SUCCESS) {
        localStorage.setItem('accessToken', JSON.stringify(response.access_token));
        localStorage.setItem('refreshToken', JSON.stringify(response.refresh_token));
      }
      if (response.status === ResponseStatus.SUCCESS) {
        return { ok: true };
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: { data: String(error), type: 'unknown_error' } };
    }
  };
