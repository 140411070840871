import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';

import { ErrorDialog } from 'ui';

import { selfRegistrationLogin } from '../selectors';
import { resendTokenToConfirmation } from '../effects/resend-token-to-confirmation';
import { StatusSignUp } from '../constants';
import { SuccessRegister } from '../components';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  row: {
    paddingBottom: theme.spacing(7),
  },
  contentFit: {
    maxWidth: 'fit-content',
  },
});

const mapDispatchToProps = dispatch => ({
  resendTokenToConfirmation: data => dispatch(resendTokenToConfirmation, data),
});

const mapStateToProps = state => ({
  selfRegistrationLogin: selfRegistrationLogin(state),
});
function SuccessRegisterPageView({ classes, history, resendTokenToConfirmation, selfRegistrationLogin }) {
  const [ login, setLogin ] = useState('');
  const [ errors, setErrors ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);
  const [ status, setStatus ] = useState('clear');
  useEffect(() => {}, [ selfRegistrationLogin ]);

  const resendToken = async () => {
    if (selfRegistrationLogin || login) {
      const { ok, error } = await resendTokenToConfirmation({ login: selfRegistrationLogin || login });
      if (ok) {
        setStatus(StatusSignUp.SUCCESS_SENT);
      } else {
        setErrors(error.data);
        setErrorShow(true);
      }
    }
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.container}>
        <Paper className={classes.paperSignIn}>
          <SuccessRegister
            classes={classes}
            login={login}
            resendToken={resendToken}
            selfRegistrationLogin={selfRegistrationLogin}
            setLogin={setLogin}
            status={status}
          />
          <ErrorDialog handleClose={setErrorShow} message={errors} show={errorShow} />
        </Paper>
      </div>
    </main>
  );
}

SuccessRegisterPageView.propTypes = {
  classes: PropTypes.object.isRequired,
};
export const SuccessRegisterPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(SuccessRegisterPageView, styles));
